import './Document.scss';
import NewDocumentCircle from 'assets/icons/documents/newDocumentCircle.svg?react';
import NewDocumentSvg from 'assets/icons/documents/newPDFLogo.svg?react';
import SeenDocumentSvg from 'assets/icons/documents/seenPDFLogo.svg?react';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { type SelectOption } from 'hooks';
import {
  type FC,
  type SyntheticEvent,
} from 'react';
import { isMobile } from 'services/mobile';
import {
  type DocumentDTO,
  getItemsFromStorage,
  type LoginAsFromStorage} from 'types';

type DocumentComponentProps = DocumentDTO & {
  displayType: SelectOption,
  documentGroupName: string,
  documentOnClick:
  (event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLElement>)
  => Promise<void>,
  downloadClick:
  (event: SyntheticEvent | undefined) => Promise<void>,
  fileByIdLoading: boolean,
  period: number | string,
  toggleDocumentViewedStatus:
  (event: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => Promise<void>,
  viewingFileByIdLoading: boolean,
};

export const UsualDocument: FC<DocumentComponentProps> = ({
  accountName,
  displayType,
  documentGroupName,
  documentOnClick,
  downloadClick,
  fileByIdLoading,
  fundName,
  period,
  toggleDocumentViewedStatus,
  userViewed,
  version,
  viewingFileByIdLoading,
}) => {
  const mobile = isMobile();
  const {loginAs} = getItemsFromStorage<LoginAsFromStorage>([
    'loginAs',
  ], sessionStorage);

  const documentLeftSide = userViewed ?
    <div className='ml-8 flex w-8 flex-row items-center gap-[0.75rem]'>
      <SeenDocumentSvg />
    </div>
    :
    <div className='ml-[1rem] flex w-12 flex-row items-center gap-[0.75rem]'>
      <NewDocumentCircle />
      <NewDocumentSvg />
    </div>;

  const buttonsSide = () =>
    <div className='show-on-hover'>
      {!userViewed && !loginAs && <Button
        className='w-max'
        data-test='mark-file-as-seen'
        icon='left'
        iconid='mark-seen'
        isLoading={viewingFileByIdLoading}
        onClick={(event) => toggleDocumentViewedStatus(event)}
        size='medium'
        type='grey'
      >
        Mark as seen
      </Button>}
      <Button
        data-test='download-file'
        icon='left'
        iconid='download-cloud'
        isLoading={fileByIdLoading}
        onClick={(event) => downloadClick(event)}
        size='medium'
      >
        Download
      </Button>
    </div>;

  const mainName = displayType.id === 'type' ? `${version ? `# ${version}` : ''} ${period}` : `${documentGroupName}`;

  const rightContentForOtherDoc = () =>
    <div className='doc-right flex w-full flex-row items-center justify-between overflow-auto'>
      <div className='doc-left-of-right mr-[1rem] flex flex-row items-center gap-[0.5rem] overflow-hidden'>
        <p className={`${userViewed && '!font-light'} mb-0 whitespace-nowrap text-font-2 font-medium text-black-999`}>
          {mainName}
        </p>
        <p className={`${userViewed && '!font-light'} dot mb-0 text-font-3 font-medium text-black-999`}>•</p>
        <p className={`${userViewed && '!font-light'} mb-0 truncate text-font-3 font-medium text-black-999`}>
          {fundName}
        </p>
      </div>
      <div className='mr-[1rem] flex min-w-max flex-row items-stretch justify-between'>
        <span className='hide-on-hover text-font-3 text-black-700'>
          {accountName}
        </span>
        {!mobile && buttonsSide()}
      </div>
    </div>;

  return (
    <div
      className='documents-container flex h-16 cursor-pointer flex-row items-center overflow-auto border-b-black-100'
      data-test={`document-${period}`}
      onClick={(event) => documentOnClick(event)}
      role='button'
      tabIndex={0}
    >
      <div className='flex flex-row items-center'>
        {documentLeftSide}
      </div>
      {rightContentForOtherDoc()}
    </div>
  );
};
