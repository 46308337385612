import ChevronRight from '../../../../assets/icons/global/chevron_right.svg?react';
import {
  CapitalAccountsSummaryPieChart,
  CapitalAccountsSummaryTable,
  CommitmentBarChart,
  ContributionBarChart,
  FullScreenModal,
} from '../../../../components';
import { useResize } from '../../../../hooks';
import { MAX_ALLOWABLE_WIDTH_FULL_SCREEN } from '../../../../resources/constants';
import { type CapitalAccountsStoreState } from '../../../../store/slices';
import style from './CapitalAccountsSummary.module.scss';
import classNames from 'classnames';
import {
  type FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

export const CapitalAccountsSummary: FC = () => {
  const {
    summaryStatistics,
    summaryStatisticsByFund,
  } = useSelector<{
    capitalAccounts: CapitalAccountsStoreState,
  }>(
    (state) => state.capitalAccounts,
  ) as CapitalAccountsStoreState;
  const containerRef = useRef<HTMLDivElement>(null);
  const { width } = useResize(containerRef);
  const [
    canRenderFullScreenButton,
    setCanRenderFullScreenButton,
  ] = useState(false);
  const [
    isFullScreen,
    setIsFullScreen,
  ] = useState(false);

  useEffect(() => {
    if (width > MAX_ALLOWABLE_WIDTH_FULL_SCREEN) {
      setCanRenderFullScreenButton(false);
    } else {
      setCanRenderFullScreenButton(true);
    }
  }, [
    width,
  ]);

  return (
    <>
      {Object.values(summaryStatisticsByFund)?.length > 1 &&
        <div className={style.capitalAccountsPieChartsContainer}>
          <CapitalAccountsSummaryPieChart
            chartType='commitment'
            title='Commitment'
          />
          <CapitalAccountsSummaryPieChart
            chartType='contribution'
            title='Contribution'
          />
          <CapitalAccountsSummaryPieChart
            chartType='nav'
            title='Net asset value'
          />
        </div>}
      {
        summaryStatistics && <div
          className={classNames(style.capitalAccountsBarChartsContainer, 'flex-col dt:flex-row')}
        >
          <ContributionBarChart values={summaryStatistics} />
          <CommitmentBarChart values={summaryStatistics} />
        </div>
      }
      <div
        className='rotateX-180 main-white-card flex-col rounded-main p-0.875'
        ref={containerRef}
      >
        {
          !isFullScreen && <CapitalAccountsSummaryTable />
        }
        {
          canRenderFullScreenButton && <button
            className={style.viewFullScreenButton}
            onClick={() => setIsFullScreen(true)}
            type='button'
          >
            <p className={style.viewFullScreenButtonText}>
              View in full screen
            </p>
            <ChevronRight />
          </button>
        }
      </div>
      <FullScreenModal
        close={() => setIsFullScreen(false)}
        isOpen={isFullScreen}
      >
        <div className='rotateX-180 main-white-card flex-col rounded-main p-0.875'>
          <CapitalAccountsSummaryTable />
        </div>
      </FullScreenModal>
    </>
  );
};
