import './DataRoom.scss';
import { DataRoomFundSelector } from './DataRoomFundSelector';
import { dataRoomApi} from 'api/dataRoomApi';
import { EmptyStateCard } from 'components/EmptyStateBanner/EmptyStateBanner';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { BASE_FMS_URL } from 'components/ReportsModule/ReportSelectors';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { type DocumentsStoreState,
  type Filters,
  type FilterStoreState } from 'store/slices';

export const DataRoom = ({folderNames}: { folderNames: string[], }) => {
  const activeFilters = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.activeFilters) as Filters | null;
  const firmId = activeFilters?.organizationId || '';
  const {
    otherCategories,
  } = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents,
  ) as DocumentsStoreState;
  const [
    showModal,
    setShowModal,
  ] = useState(false);

  const onFolderSelected = async (folderName: string) => {
    const token = await dataRoomApi.getDataRoomToken({firmId,
      folderName});

    if (token) {
      const newWindow = window.open('', '_blank');
      const fmsUrl = `${BASE_FMS_URL}/documents/data-room/external?token=${token}`;
      if (newWindow) {
        newWindow.location.href = fmsUrl;
      }
    }
  };

  const handleButtonClick = () => {
    if (folderNames.length === 1) {
      onFolderSelected(folderNames[0]);
    } else {
      setShowModal(true);
    }
  };

  if (!otherCategories?.length) {
    return <EmptyStateCard
      buttonType='white'
      containerClass='data-room'
      dataTest='data-room-modal-open'
      description='Store and share data in a simple way'
      hasItems={Boolean(folderNames.length)}
      onViewClick={handleButtonClick}
      title='Data room'
    />;
  }

  return (
    <>
      <div className='data-room-card flex w-full flex-col justify-between gap-0.75 rounded-main p-0.875'>
        <div className='bold-[600] text-font-1 text-white-999'>
          Data room
        </div>
        <Button
          className='!h-8 w-[5.5rem]'
          data-test='data-room-modal-open'
          onClick={handleButtonClick}
          size='medium'
          tooltipWrapperClass='!justify-start'
          type='white'
        >
          View
        </Button>
      </div>
      {showModal && <DataRoomFundSelector
        folderNames={folderNames}
        onClose={() => setShowModal(false)}
        onFolderSelected={onFolderSelected}
      />}
    </>
  );
};
