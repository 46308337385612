import './DataRoom.scss';
import { Modal } from 'components/InteractiveUIControls/Modal/Modal';
import { ModalTop } from 'components/InteractiveUIControls/Modal/Modaltop';
import { Svgicon } from 'components/Svgicon/Svgicon';

const FolderOption = ({
  folderName,
  onFolderSelected,
}: {
  folderName: string,
  onFolderSelected: (folderName: string) => void,
}) => {
  return (
    <div
      className='border-bottom-1 flex w-full cursor-pointer justify-between border-b-[1px] border-b-grey-100 px-0.5 py-0.75 hover:bg-black-100 active:bg-black-200'
      onClick={() => onFolderSelected(folderName)}
    >
      <span className='truncate text-font-2'>{folderName}</span>
      <Svgicon className='size-1 min-w-[1rem] text-black-500' id='arrow-right-1f' />
    </div>
  );
};

export const DataRoomFundSelector = ({
  folderNames,
  onClose,
  onFolderSelected,
}: {
  folderNames: string[],
  onClose: () => void,
  onFolderSelected: (folderName: string) => void,
}) => {
  return (
    <Modal onToggle={onClose} showCloseIcon>
      <ModalTop centred title='Select folder' />
      <div className='main-scroll w-[28rem] p-1.25'>
        <div className='max-h-[18.75rem] overflow-y-auto'>
          {folderNames.map((folderName) =>
            <FolderOption
              folderName={folderName}
              key={folderName}
              onFolderSelected={onFolderSelected}
            />,
          )}
        </div>
      </div>
    </Modal>
  );
};
