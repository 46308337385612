import {
  type CapitalAccountsStoreState,
  type Filters,
  type FilterStoreState,
  setSortedCapitalAccountsData,
  targetKeys,
} from '../../../store/slices';
import {
  ColumnsToDisplay,
  type ComputedCapitalAccountFund,
  type ComputedCapitalAccountInfoAccount,
  SecondTableColumns} from '../../../types';
import {
  getBlankColumn,
  Table,
} from '../../Table';
import { CapitalAccountsExpandIcon } from './CapitalAccountsExpandIcon';
import { CapitalAccountsFundTable } from './CapitalAccountsFundTable';
import { CapitalAccountsSummaryTableRow } from './CapitalAccountsSummaryTableRow';
import { DoubleTitle } from './DoubleTitle';
import { type ColumnsType,
  type TableProps,
} from 'antd/lib/table';
import {
  type FilterValue,
  type SorterResult,
  type TablePaginationConfig,
} from 'antd/lib/table/interface';
import { capitalAccountsApi } from 'api/capitalAccountsApi';
import { Svgicon } from 'components/Svgicon/Svgicon';
import {
  type FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createRoot } from 'react-dom/client';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { notificationsService } from 'services';

const HORIZONTAL_SCROLL_THRESHOLD = 58;

const getCommitment = (account: ComputedCapitalAccountInfoAccount): number => {
  return Object.values(account.periods).reduce((sum, period) => {
    return sum + (period.commitment ?? 0);
  }, 0);
};

const CapitalAccountsSummaryTable: FC = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const downloadHandlerRef = useRef(() => {});
  const dispatch = useDispatch();
  const [
    dropdownCloseSignal,
    setDropdownCloseSignal,
  ] = useState(false);
  const {
    computedCapitalAccountsData,
    selectedFundAccountPeriodMap,
  } = useSelector<{
    capitalAccounts: CapitalAccountsStoreState,
  }>(
    (state) => state.capitalAccounts,
  ) as CapitalAccountsStoreState;
  const activeFilters = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.activeFilters) as Filters | null;
  const [
    expandedRowKeys,
    setExpandedRowKeys,
  ] = useState<string[]>([]);
  const filteredSecondTableColumns = SecondTableColumns.filter(([
    key,
  ]) => {
    if (targetKeys.includes(key as typeof targetKeys[number])) {
      const hasData = computedCapitalAccountsData?.some((fund) =>
        fund.accounts.some((account) => {
          const selectedPeriodId = selectedFundAccountPeriodMap[fund._id]?.[account._id];
          if (!selectedPeriodId) {
            return false;
          }

          const selectedPeriod = account.periods[selectedPeriodId];
          if (!selectedPeriod) {
            return false;
          }

          const value = selectedPeriod[key as keyof typeof selectedPeriod];
          return Boolean(value);
        }),
      );
      return hasData;
    }

    return true;
  });

  useEffect(() => {
    downloadHandlerRef.current = async () => {
      if (activeFilters) {
        capitalAccountsApi.downloadCapitalAccountsReport({
          accountIds: activeFilters.accountIds,
          currentPeriod: selectedFundAccountPeriodMap,
          firmId: activeFilters.organizationId,
        });
        notificationsService.success('Download has started');
      }
    };
  }, [
    activeFilters,
    selectedFundAccountPeriodMap,
  ]);

  useEffect(() => {
    const iconCells = tableContainerRef.current?.querySelectorAll(
      '.ant-table-row-expand-icon-cell',
    );

    if (iconCells) {
      const existingIcon = iconCells[0].querySelector('.custom-download-icon');

      if (!existingIcon) {
        const downloadIcon = document.createElement('span');
        downloadIcon.className = 'custom-download-icon flex justify-center text-blue-500 cursor-pointer';

        downloadIcon.onclick = () => {
          downloadHandlerRef.current();
        };

        iconCells[0].appendChild(downloadIcon);

        createRoot(downloadIcon).render(<Svgicon className='!size-[1rem]' id='download-1f' />);
      }
    }
  }, []);

  useEffect(() => {
    if (
      computedCapitalAccountsData?.length &&
      !expandedRowKeys.length
    ) {
      setExpandedRowKeys(computedCapitalAccountsData.map((item) => item._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    computedCapitalAccountsData,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        tableContainerRef.current?.scrollLeft &&
        tableContainerRef.current?.scrollLeft > HORIZONTAL_SCROLL_THRESHOLD) {
        setDropdownCloseSignal(true);
      } else {
        setDropdownCloseSignal(false);
      }
    };

    const container = tableContainerRef.current;

    container?.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const columns: ColumnsType<ComputedCapitalAccountFund> = [
    {
      className: 'table-column-width-17p1875',
      dataIndex: 'legalName',
      defaultSortOrder: 'ascend',
      key: 'legalName',
      render: (
        _: string, rc: ComputedCapitalAccountFund,
      ) => <div className='flex h-full items-center gap-[0.5rem]'>
        <p className='m-0 font-medium'>{rc.legalName}</p>
        <div className='flex items-center justify-center rounded-[100%] bg-black-300 px-[0.25rem] py-[0.0625rem]'>{rc.accounts.length}</div>
      </div>,
      showSorterTooltip: false,
      sortDirections: [
        'descend',
        'ascend',
        'descend',
      ],
      sorter: (a: ComputedCapitalAccountFund, b: ComputedCapitalAccountFund) =>
        a.entityNumber as number - (b.entityNumber as number),
      title: <DoubleTitle
        subTitle={ColumnsToDisplay.investor.subtitle}
        title={ColumnsToDisplay.investor.title}
      />,
    },
    {
      className: 'table-column-width-9p375',
      title: <DoubleTitle
        title={ColumnsToDisplay.reportingPeriod.title}
      />,
    },
    {
      className: 'table-column-width-9p375',
      key: 'commitment',
      sortDirections: [
        'descend',
        'ascend',
        'descend',
      ],
      sorter: true,
      title: <DoubleTitle
        subTitle={ColumnsToDisplay.commitment.subtitle}
        title={ColumnsToDisplay.commitment.title}
      />,
    },
    getBlankColumn(),
    ...filteredSecondTableColumns.map((column) => ({
      className: 'table-column-width-9p375',
      title: <DoubleTitle subTitle={column[1].subtitle} title={column[1].title} />,
    })),
    {
      className: 'table-column-width-9p375',
      title: <DoubleTitle
        subTitle={ColumnsToDisplay.other.subtitle}
        title={ColumnsToDisplay.other.title}
      />,
    },
    getBlankColumn(),
    {
      className: 'table-column-width-9p375',
      title: <DoubleTitle
        subTitle={ColumnsToDisplay.nav.subtitle}
        title={ColumnsToDisplay.nav.title}
      />,
    },
  ];

  const handleTableChange: TableProps<ComputedCapitalAccountFund>['onChange'] = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: Array<SorterResult<ComputedCapitalAccountFund>> |
    SorterResult<ComputedCapitalAccountFund>) => {
    const { columnKey,
      order } = sorter as SorterResult<ComputedCapitalAccountFund>;

    if (columnKey === 'commitment' && computedCapitalAccountsData) {
      const sortedData = computedCapitalAccountsData.map((fund) => ({
        ...fund,
        accounts: [
          ...fund.accounts,
        ].sort((a, b) => {
          const [
            commitmentA,
            commitmentB,
          ] = [
            getCommitment(a),
            getCommitment(b),
          ];
          return order === 'ascend' ? commitmentA - commitmentB : commitmentB - commitmentA;
        }),
      }));

      dispatch(setSortedCapitalAccountsData({ sortedData }));
    }
  };

  if (!computedCapitalAccountsData) {
    return null;
  }

  const getSummaryRowComponent = () => <CapitalAccountsSummaryTableRow
    data={computedCapitalAccountsData}
    filteredSecondTableColumns={filteredSecondTableColumns}
    selectedFundAccountPeriodMap={selectedFundAccountPeriodMap}
  />;

  return <div className={'zoom-125 table-scroll z-0 -mt-14 overflow-x-auto overflow-y-hidden pt-14'} ref={tableContainerRef}>
    <Table
      className='ant-table-without-top-border rotateX-180 min-w-[56.375rem]'
      columns={columns}
      dataSource={computedCapitalAccountsData}
      expandable={{
        columnWidth: '2.0625rem',
        defaultExpandAllRows: true,
        expandedRowKeys,
        expandedRowRender: (
          record: ComputedCapitalAccountFund,
        ) => <CapitalAccountsFundTable
          dropdownCloseSignal={dropdownCloseSignal}
          filteredSecondTableColumns={filteredSecondTableColumns}
          record={record}
          selectedAccountPeriodMap={selectedFundAccountPeriodMap[record._id]}
          withoutSubtotalSummary={computedCapitalAccountsData.length === 1}
        />,
        expandIcon: CapitalAccountsExpandIcon,
        onExpand: (expanded, record) => {
          setExpandedRowKeys(expanded ? expandedRowKeys.concat(record._id)
            : expandedRowKeys.filter((key) => key !== record._id));
        },
      }}
      onChange={handleTableChange}
      rowKey={'_id'}
      summary={getSummaryRowComponent}
    />
  </div>;
};

export { CapitalAccountsSummaryTable };
