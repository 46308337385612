import {authActionTypes} from '../store/actions';
import style from '../styles/alert.module.scss';
import {
  type AuthorizeVerificationType,
  type NextPageAfterVerificationFromStorage,
  removeItemsFromStorage,
  storeItemsInStorage } from '../types';
import { type Action,
  type Dispatch,
  type ThunkDispatch } from '@reduxjs/toolkit';
import {notification} from 'antd';
import { type NavigateFunction } from 'react-router-dom';

export type AsyncThunkAction = ThunkDispatch<unknown, unknown, Action<string>>;

export const actionCreator = <T>(type: string, payload: T) => ({
  payload,
  type,
});

export const Alert = (message: string) => {
  const key = `open${Date.now()}`;

  notification.open({
    className: style.antNotificationNotice,
    key,
    message,
  });
};

export const unauthorized = (status: number, callbackLocation?: string) => {
  if (status !== 401) {
    return;
  }

  localStorage.clear();
  sessionStorage.clear();
  window.location.pathname = callbackLocation || '/auth/login';
};

export const processAuthorization = (
  authData: AuthorizeVerificationType,
  dispatch: Dispatch<{payload: unknown, type: string, }>,
  actionToDispatch: () => void,
  navigate: NavigateFunction,
  verificationRoute: string,
  noVerificationRoute: string,
  afterLoginCallback?: () => void,
) => {
  const {
    isVerificationRequired,
    verificationNotRequiredPayload,
    verificationRequiredPayload,
  } = authData;

  if (isVerificationRequired) {
    storeItemsInStorage(verificationRequiredPayload, sessionStorage);
    sessionStorage.setItem('nextPageAfterVerification', noVerificationRoute);
    storeItemsInStorage<NextPageAfterVerificationFromStorage>({
      nextPageAfterVerification: noVerificationRoute,
    }, sessionStorage);
    navigate(verificationRoute);
  } else {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      access_token,
      isMFAmethodFinalised,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      refresh_token,
    } = verificationNotRequiredPayload;

    dispatch(actionCreator(
      authActionTypes.SET_LOGIN_SUCCESS,
      verificationNotRequiredPayload,
    ));

    // only need to store accessToken and refreshToken in storage
    storeItemsInStorage({
      access_token,
      refresh_token,
    }, sessionStorage);

    actionToDispatch();

    if (afterLoginCallback) {
      afterLoginCallback();
    }

    if (isMFAmethodFinalised === false) {
      // MFA Method is not finalised, redirect to /auth/select-auth-method
      storeItemsInStorage({
        isMFAmethodFinalised: 'false',
      }, sessionStorage);

      navigate('/auth/select-auth-method');

      return;
    }

    // Reach here, can remove isMFAmethodFinalised
    removeItemsFromStorage([
      'isMFAmethodFinalised',
    ], sessionStorage);

    navigate(noVerificationRoute);
  }
};
