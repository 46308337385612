import {PrivacyAndTerms} from './components';
import {Authorization,
  Firms,
  FirmWithModalContexts} from './pages';
import classNames from 'classnames';
import { OtherSoftwareSolutions } from 'components/OtherSoftwareSolutions/OtherSoftwareSolutions';
import {FiltersProvider} from 'contexts';
import {useCheckUserAuthenticationFromMe} from 'hooks';
import { DownloadArchive } from 'pages/DownloadArchive/DownloadArchive';
import {Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import {environmentService,
  scriptsService} from 'services';

const App = () => {
  const pathname = useLocation().pathname;
  const isAuthPage = pathname.startsWith('/auth');
  const isFirmPage = pathname.startsWith('/firm') && !pathname.startsWith('/firms');

  useCheckUserAuthenticationFromMe([
    '/firm/*',
    '/firms',
  ]);

  const classes = classNames(
    'app flex h-auto min-h-full flex-col justify-between',
    {
      'bg-black-100': !isAuthPage,
    },
  );

  return (
    <div className={classes} >
      <FiltersProvider>
        <Routes>
          <Route element={<Authorization />} path='/auth/*' />
          <Route element={<FirmWithModalContexts />} path='/firm/*' />
          <Route element={<Firms />} path='/firms' />
          <Route element={<DownloadArchive />} path='/download-archive/:jobId' />
          <Route element={<Navigate replace to='/auth/login' />} path='*' />
        </Routes>
        <div>
          {isAuthPage ? <OtherSoftwareSolutions isMobile /> : null}
          {(isAuthPage || isFirmPage) && <PrivacyAndTerms />}
        </div>

      </FiltersProvider>
      {
        environmentService.isProduction() &&
          scriptsService.insertJsScriptSource(
            scriptsService.SCRIPTS.googleAnalyticsTagSrc,
          )
      }
      {
        environmentService.isProduction() &&
          scriptsService.insertJsScriptText(
            scriptsService.SCRIPTS.googleAnalyticsTagScript,
          )
      }
    </div>
  );
};

export default App;
