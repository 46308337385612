export const CHART_COLORS = {
  black: '#263238',
  blue: '#0076E6',
  commitmentBarChart: [
    '#0076E6',
    '#FBDB5B',
    '#64AEAB',
    '#E6E7E8',
  ],
  contributionBarChart: [
    '#64AEAB',
    '#0076E6',
    '#E6E7E8',
  ],
  default: [
    '#0076E6',
    '#80C1FF',
    '#64AEAB',
    '#9FD2D0',
    '#FBDB5B',
    '#9AA6B2',
  ],
  green: '#64AEAB',
  grey: '#9AA6B2',

};

export const circularColor = (
  index: number,
  dataLen: number,
  colorList: string[],
): string => {
  const lastIndex = dataLen - 1;
  if (index % colorList.length === 0 && index === lastIndex) {
    return colorList[1];
  }

  return colorList[index % colorList.length];
};
