import './EmptyStateBanner.scss';
import classNames from 'classnames';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { isTablet } from 'services/mobile';

type ContainerClass = 'capital-accounts' | 'data-room' | 'reports';
type ButtonType = 'total-black' | 'white';

type EmptyStateCardProps = {
  buttonType?: ButtonType,
  containerClass: ContainerClass,
  dataTest: string,
  description: string,
  hasItems: boolean,
  onViewClick: () => void,
  title: string,
};

export const EmptyStateCard: React.FC<EmptyStateCardProps> = ({
  buttonType = 'total-black',
  containerClass,
  dataTest,
  description,
  hasItems,
  onViewClick,
  title,
}) => {
  const tablet = isTablet();

  return (
    <div
      className={classNames(
        'flex h-80 max-h-max w-1/3 min-w-[13.25rem] rounded-main px-[1.25rem] py-6',
        {
          'min-h-40 !w-full !p-1': tablet,
          'opacity-50': !hasItems,
          'w-4/5': hasItems,
        },
        `${containerClass}-card-empty-state`,
      )}
    >
      <div
        className={classNames('flex flex-col justify-end gap-6', {
          'justify-between': tablet,
        })}
      >
        <div
          className={classNames('flex flex-col gap-[0.5rem]', {
            'h-full justify-between': tablet && !hasItems,
          })}
        >
          <h3 className='m-0 flex flex-col gap-[0.5rem] text-title-5 font-semibold'>
            {title}
          </h3>
          <p className='m-0 text-font-2 font-normal'>{description}</p>
        </div>
        {hasItems ?
          <Button
            className='!h-8 w-[5.5rem]'
            data-test={dataTest}
            onClick={onViewClick}
            size='medium'
            tooltipWrapperClass='!justify-start'
            type={buttonType}
          >
            View
          </Button>
          : null}
      </div>
    </div>
  );
};
