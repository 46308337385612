import authApi from '../../api/auth';
import LogoutSvg from '../../assets/icons/global/logout.svg?react';
import ProfileSvg from '../../assets/icons/global/profile.svg?react';
import SettingsSvg from '../../assets/icons/global/settings.svg?react';
import SupportSvg from '../../assets/icons/global/support.svg?react';
import MenuSvg from '../../assets/icons/sidebar/menu.svg?react';
import MenuCloseSvg from '../../assets/icons/sidebar/menu-close.svg?react';
import { ModalActivationContext } from '../../contexts';
import { isMobile } from '../../services/mobile';
import style from './Profile.module.scss';
import { DownloadAllFilesBlock } from 'components/DownloadAllFilesBlock';
import { useRelativePathsToBasePath } from 'hooks';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { type AppDispatch } from 'store';
import { getItemsFromStorage } from 'types';

const VALID_PATHS_FOR_ACCOUNTS = [
  '/firm/capital-accounts/*',
];

export const Profile = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { toggleSettingsModal } = useContext(ModalActivationContext);
  const componentRef = useRef(
    null,
  ) as unknown as React.MutableRefObject<HTMLDivElement>;
  const { valid: isAccountsPath } =
    useRelativePathsToBasePath(...VALID_PATHS_FOR_ACCOUNTS);

  const [
    mobile,
    setMobile,
  ] = useState(isMobile());

  const {
    showSupportModal,
    toggleSupportModal,
  } = useContext(ModalActivationContext);

  const [
    showSelection,
    setShowSelection,
  ] = useState(false);

  const { loginAs } = getItemsFromStorage([
    'loginAs',
  ], sessionStorage);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      showSelection &&
      componentRef.current &&
      !componentRef.current.contains(event.target as Node)
    ) {
      setShowSelection(false);
    }
  }, [
    showSelection,
  ]);

  const handleSettingsClick = useCallback(() => {
    toggleSettingsModal(true);
    setShowSelection(false);
  }, [
    navigate,
    toggleSettingsModal,
  ]);

  const handleSupportClick = useCallback(() => {
    toggleSupportModal(!showSupportModal);
    setShowSelection(false);
  }, [
    showSupportModal,
    toggleSupportModal,
  ]);

  const handleProfileButtonClick = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>) => {
      event?.stopPropagation();
      setShowSelection(!showSelection);
    }, [
      showSelection,
    ]);

  const logout = () => {
    toggleSupportModal(false);
    dispatch(authApi.logout(navigate));
  };

  window.onresize = () => {
    setMobile(isMobile());
  };

  useEffect(() => {
    // Add a click event listener to the document
    document.addEventListener('click', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener('click', handleClickOutside);
    };
  }, [
    handleClickOutside,
  ]);

  const menu = () => <div className={style.activeBlock} ref={componentRef}>
    <div
      className={style.menuContainer}
    >
      <h3 className={style.menuText}>Menu</h3>
      <button
        className={style.profileButton}
        data-test={'click-profile-button'}
        onClick={handleProfileButtonClick}
        type='button'
      >
        <MenuCloseSvg />
      </button>
    </div>
    {mobile && <div
      className={style.blockOption}
      data-test={'click-download-button'}
      onClick={() => setShowSelection(false)}
    >
      <DownloadAllFilesBlock />
    </div>}
    {!loginAs && <button
      className={style.blockOption}
      data-test={'click-settings-button'}
      onClick={handleSettingsClick}
      type='button'
    >
      <SettingsSvg />
      <span className='ml-0.25 text-font-3'>
        Settings
      </span>
    </button>}
    <button
      className={style.blockOption}
      data-test={'click-support-button'}
      onClick={handleSupportClick}
      type='button'
    >
      <SupportSvg />
      <span className='ml-0.25 text-font-3'>
        Get Support
      </span>
    </button>
    <button
      className={style.blockOption}
      data-test={'click-logout-button'}
      onClick={logout}
      type='button'
    >
      <LogoutSvg />
      <span className='ml-0.25 text-font-3'>
        Logout
      </span>
    </button>
  </div>;

  return (
    <div className='flex items-center gap-0.5'>
      {!mobile && !isAccountsPath && <DownloadAllFilesBlock />}
      <div className={style.profileContainer}>
        {showSelection && <div className={style.backdrop} />}
        <button
          className={style.profileButton}
          data-test={'click-profile-button'}
          onClick={handleProfileButtonClick}
          type='button'
        >
          {mobile ? <MenuSvg /> : <ProfileSvg />}
        </button>
        {showSelection && menu()}
      </div>
    </div>
  );
};
