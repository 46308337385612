/* eslint-disable react/forbid-component-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import RemoveFile from '../../assets/icons/global/defaultRemoveFile.svg?react';
import style from './FileUploader.module.scss';
import { convertMBToBytes,
  DUPLICATE_FILES_MESSAGE,
  ERROR_INITIAL_STATE,
  FILES_ARE_TOO_BIG_ERROR_MESSAGE,
  MAX_SIZE_OF_FILES_IN_MB } from './utils';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Svgicon } from 'components/Svgicon/Svgicon';
import { type ChangeEvent,
  type FC,
  useEffect,
  useRef,
  useState } from 'react';

type FileUploaderProps = {
  children?: React.ReactNode,
  inputProps?: React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  maxSizeInMb?: number,
  onFilesUpdate: (files: File[]) => void,
};

export const FileUploader: FC<FileUploaderProps> = ({ children,
  inputProps,
  maxSizeInMb = MAX_SIZE_OF_FILES_IN_MB,
  onFilesUpdate }) => {
  const [
    files,
    setFiles,
  ] = useState<File[]>([]);
  const [
    bytesLeft,
    setBytesLeft,
  ] = useState(convertMBToBytes(MAX_SIZE_OF_FILES_IN_MB));
  const [
    error,
    setError,
  ] = useState(ERROR_INITIAL_STATE);
  const fileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    onFilesUpdate(files);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    files,
  ]);

  useEffect(() => {
    if (error.status) {
      setTimeout(() => {
        setError(ERROR_INITIAL_STATE);
      }, 4_000);
    }
  }, [
    error.status,
  ]);

  const addNewFiles = (currentFiles: File[], newFiles: File[]) => {
    const totalSizeOfNewFiles = newFiles.reduce(
      (previous, current) => previous + current.size, 0,
    );

    if (totalSizeOfNewFiles > bytesLeft) {
      setError({
        message: FILES_ARE_TOO_BIG_ERROR_MESSAGE(maxSizeInMb),
        status: true,
      });
      return [
        ...currentFiles,
      ];
    }

    const hasDuplicateFileName = newFiles
      .some((newFile) => currentFiles.some(
        (oldFile) => newFile.name === oldFile.name,
      ));

    if (hasDuplicateFileName) {
      setError({
        message: DUPLICATE_FILES_MESSAGE,
        status: true,
      });
      return [
        ...currentFiles,
      ];
    }

    const newFilesAdded = [
      ...currentFiles,
    ];

    newFilesAdded.push(...newFiles);

    setBytesLeft((bytes) => bytes - totalSizeOfNewFiles);

    return newFilesAdded;
  };

  const handleNewFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files;
    if (!newFiles) {
      return;
    }

    const newFileArray = Array.from(newFiles);
    if (!newFileArray.length) {
      return;
    }

    const newFileMap = addNewFiles(files, newFileArray);
    setFiles(() => newFileMap);
    event.target.value = '';
  };

  const removeFile = (currentFiles: File[], fileToBeRemovedName: string) => {
    const foundFile = currentFiles
      .find((file) => file.name === fileToBeRemovedName);

    if (!foundFile) {
      return [
        ...currentFiles,
      ];
    }

    const newFiles = currentFiles
      .filter((file) => file.name !== fileToBeRemovedName);

    setBytesLeft((bytes) => bytes + foundFile.size);

    return newFiles;
  };

  const handleFileRemove = (fileToBeRemovedName: string) => () => {
    const newFileMap = removeFile(files, fileToBeRemovedName);
    setFiles(() => newFileMap);
  };

  return (
    <div className={style.container}>
      {files?.length > 0 && <div className={style.filesPreviewContainer}>
        {
          files.map((file) => <div
            className={style.filePreviewContainer}
            key={file.name}
          >
            <Svgicon className='size-1.5' id='document-3f' />
            <div className='text-font-2'>{file.name}</div>
            <div
              className={style.removeButtonContainer}
              onClick={handleFileRemove(file.name)}
            >
              <RemoveFile />
            </div>
          </div>)
        }
      </div>}
      <div className={style.uploadContainer}>
        <Button
          icon='left'
          iconClassName='size-[1rem]'
          iconid='upload-cloud'
          onClick={() => fileInput.current?.click()}
          size='bigger-medium'
          tooltipWrapperClass='!justify-start'
          type='outline-main'
        >
          <span>Attach Documents</span>
        </Button>
        {
          error.status && <div className={style.error}>{error.message}</div>
        }
        <input
          accept='.jpg,.png,.jpeg,.pdf'
          className={style.inputForm}
          multiple
          onChange={handleNewFileUpload}
          ref={fileInput}
          style={{ display: 'none' }}
          type='file'
          {...inputProps}
        />
      </div>
      {
        children
      }
    </div>
  );
};
