import { type TableColumnDisplayValues } from '../../../types';
import {CapitalAccountsTotalsChart,
  type TotalsChartItem } from '../CapitalAccountsTotalsChart';
import { type FC } from 'react';

export const NavTotals: FC<TableColumnDisplayValues> = ({
  contribution,
  contributionDue,
  distribution,
  distributionWth,
  other,
  realizedGainLoss,
  unrealizedGainLoss,
}) => {
  const netGainLoss = unrealizedGainLoss + realizedGainLoss + other;
  const totalValue =
  contribution + distribution + netGainLoss + distributionWth + contributionDue;
  const items: TotalsChartItem[] = [
    {
      legend: 'Contribution',
      value: contribution,
    },
    {
      legend: '(Due)/Advance',
      value: contributionDue,
    },
    ...distribution === 0 ? [] : [
      {
        legend: 'Distribution',
        value: distribution,
      },
    ],
    ...distributionWth === 0 ? [] : [
      {
        legend: 'Withholding tax',
        value: distributionWth,
      },
    ],
    {
      legend: 'Net Gain/(Loss)',
      value: netGainLoss,
    },
  ];

  return <CapitalAccountsTotalsChart
    items={items}
    title='Net asset value'
    totalValue={totalValue}
  />;
};
