import {
  type ColumnsToDisplayEnum,
  type ColumnTitle,
  type ComputedCapitalAccountFund,
  type ComputedCapitalAccountInfoAccount,
  type FixedColumns,
  SecondTableColumnKeys,
} from '../../../../types';
import {
  getBlankColumn,
  Table,
} from '../../../Table';
import { CapitalAccountsDataRenderer } from './CapitalAccountsDataRenderer';
import { CapitalAccountsFundTableSubtotalRow } from './CapitalAccountsFundTableSubtotalRow';
import { CapitalAccountsInvestorRow } from './CapitalAccountsInvestorRow/CapitalAccountsInvestorRow';
import { CapitalAccountsPeriodSelector } from './CapitalAccountsPeriodSelector/CapitalAccountsPeriodSelector';
import {
  type ColumnsType,
  type ColumnType,
} from 'antd/lib/table';
import { type FC } from 'react';

export type TotalsForFilterColumns = {
  contributionDue: number,
  distribution: number,
  distributionWth: number,
  realizedGainLoss: number,
};

export type CapitalAccountsFundTableProps = {
  dropdownCloseSignal?: boolean,
  filteredSecondTableColumns: Array<[string, ColumnTitle]>,
  record: ComputedCapitalAccountFund,
  selectedAccountPeriodMap: Record<string, string>,
  withoutSubtotalSummary?: boolean,
};

export const CapitalAccountsFundTable: FC<CapitalAccountsFundTableProps> = ({
  dropdownCloseSignal,
  filteredSecondTableColumns,
  record,
  selectedAccountPeriodMap,
  withoutSubtotalSummary = false,
}) => {
  const keysAsMandatoryColumnKeys = filteredSecondTableColumns
    .map(([
      key,
    ]) => key as keyof FixedColumns)
    .filter((key) => SecondTableColumnKeys.includes(key as ColumnsToDisplayEnum));

  const columns: ColumnsType<ComputedCapitalAccountInfoAccount> = [
    {
      className: 'table-column-width-action',
    },
    {
      className: 'table-column-width-17p1875',
      dataIndex: 'legalName',
      key: 'legalName',
      render: (
        _: string, rc: ComputedCapitalAccountInfoAccount,
      ) => <CapitalAccountsInvestorRow
        _id={rc._id}
        fundId={record._id}
        legalName={rc.legalName}
      />,
      title: 'Fund',
    },
    {
      className: 'table-column-width-9p375',
      dataIndex: 'reportingPeriod',
      key: 'reportingPeriod',
      onCell: (_: unknown, index?: number) => ({
        style: {
          position: 'relative',
          zIndex: index ? record.accounts.length - index : record.accounts.length,
        },
      }),
      render: (
        _: string, rc: ComputedCapitalAccountInfoAccount,
      ) => <CapitalAccountsPeriodSelector
        _id={rc._id}
        closeSignal={dropdownCloseSignal}
        fundId={record._id}
        periodList={Object.keys(rc.periods)}
      />,
      title: 'Fund',
    },
    {
      className: 'table-column-width-9p375',
      dataIndex: 'commitment',
      key: 'commitment',
      render: (
        _: string, rc: ComputedCapitalAccountInfoAccount,
      ) => <CapitalAccountsDataRenderer
        accountId={rc._id}
        fundId={record._id}
        periods={rc.periods}
        valueKey='commitment'
      />,
      title: 'commitment',
    },
    getBlankColumn(),
    ...keysAsMandatoryColumnKeys
      .map<ColumnType<ComputedCapitalAccountInfoAccount>>((key) => ({
      className: 'table-column-width-9p375',
      dataIndex: key,
      key,
      render: (
        _: string, rc: ComputedCapitalAccountInfoAccount,
      ) => <CapitalAccountsDataRenderer
        accountId={rc._id}
        fundId={record._id}
        periods={rc.periods}
        valueKey={key}
      />,
      title: key,
    })),
    {
      className: 'table-column-width-9p375',
      dataIndex: 'other',
      key: 'other',
      render: (
        _: string, rc: ComputedCapitalAccountInfoAccount,
      ) => <CapitalAccountsDataRenderer
        accountId={rc._id}
        fundId={record._id}
        periods={rc.periods}
        valueKey='other'
      />,
      title: 'other',
    },
    getBlankColumn(),
    {
      className: 'table-column-width-9p375',
      dataIndex: 'nav',
      key: 'nav',
      render: (
        _: string, rc: ComputedCapitalAccountInfoAccount,
      ) => <CapitalAccountsDataRenderer
        accountId={rc._id}
        fundId={record._id}
        periods={rc.periods}
        valueKey='nav'
      />,
      title: 'nav',
    },
  ];

  const getSubtotalRowComponent = () => <CapitalAccountsFundTableSubtotalRow
    data={record.accounts}
    filteredSecondTableColumnKeys={keysAsMandatoryColumnKeys}
    fundId={record._id}
    fundName={record.legalName}
    selectedAccountPeriodMap={selectedAccountPeriodMap}
    withoutSubtotalSummary={withoutSubtotalSummary}
  />;

  return <Table
    columns={columns}
    dataSource={record.accounts}
    pagination={false}
    rowKey='_id'
    showHeader={false}
    summary={getSubtotalRowComponent}
  />;
};
