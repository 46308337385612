import style from './DocumentCategories.module.scss';
import { EmptyDocument } from './DocumentCategory/DocumentGroup/Documents/Document/components/EmptyDocument';
import { EmptyDocumentCategory } from './DocumentCategory/EmptyDocumentCategory';
import { isMobile } from 'services/mobile';

export const EmptyDocumentCategories = () => {
  const mobile = isMobile();
  return <>
    <div className='relative flex w-full max-w-[90rem] flex-col gap-[1rem]'>
      <h3 className={style.allDocumentsText}>
        Recent documents
      </h3>
      <div
        className='relative flex w-full max-w-[90rem] items-center'
      >
        <div className='flex w-full gap-[0.5rem]'>
          {Array.from({ length: mobile ? 1 : 4 }).map((index, key) =>
            // eslint-disable-next-line react/no-array-index-key
            <div className={`${mobile ? 'w-full' : 'w-1/4'}`} key={key}>
              <EmptyDocument />
            </div>,
          )}
        </div>
      </div>
    </div>
    <div className={style.categoriesContainer}>
      <h3 className={style.allDocumentsText}>
        All documents
      </h3>
      <EmptyDocumentCategory />
    </div></>;
};
