/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Ticked from '../../../assets/icons/global/tick.svg?react';
import style from './DropdownOption.module.scss';
import classNames from 'classnames';
import { type SelectDisplayOption } from 'hooks';
import { type FC } from 'react';

type DropdownOptionProps = {
  dataSelectionValue: string,
  onClickOption: (option: SelectDisplayOption) =>
  (event: React.MouseEvent<HTMLDivElement>) => void,
  option: SelectDisplayOption,
  optionContainerStyle?: string,
  optionTextStyle?: string,
  selectedOptionContainerStyle?: string,
  withTickIcon: boolean,
};

export const DropdownOption: FC<DropdownOptionProps> = ({
  dataSelectionValue,
  onClickOption,
  option,
  optionContainerStyle,
  optionTextStyle,
  selectedOptionContainerStyle,
  withTickIcon}) => {
  return (
    <div
      className={classNames(style.option, optionContainerStyle, {
        [selectedOptionContainerStyle as string]: option.selected,
      })}
      data-closest='option'
      data-test={`${dataSelectionValue}-option`}
      id={option.id}
      onClick={onClickOption(option)}
      title={option.name}
    >
      <div className={`${style.optionText} ${optionTextStyle}`}>
        {option.name}
      </div>
      {option.selected && withTickIcon && <Ticked />}
    </div>
  );
};
