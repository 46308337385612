import { ButtonArea } from '../ButtonArea/ButtonArea';
import { useBlockDisplay } from '../contexts/BlockDisplayContext.context';
import { ChangePhoneNumberContextProvider } from '../contexts/ChangePhoneNumber.context';
import { ConfirmBlock } from './ConfirmBlock/ConfirmBlock';
import { VerificationMethodCard } from './VerificationMethodCard/VerificationMethodCard';
import authApi from 'api/auth';
import {
  halfHideEmail,
  type To} from 'pages/Authorization/utils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CHANNEL_MAP,
  VerificationChannel } from 'types';

const maskNumber = (number: string) => number.slice(-4).padStart(number.length, '*');

export const getSendToParagraph = (
  channel: VerificationChannel,
  to: To,
  currentVerificationChannel: VerificationChannel,
) => {
  const { email,
    phone } = to;

  const getDestination = () => {
    if (channel === VerificationChannel.Email) {
      return halfHideEmail(email);
    }

    if (channel === VerificationChannel.None) {
      return currentVerificationChannel === VerificationChannel.Email
        ? halfHideEmail(email)
        : maskNumber(phone);
    }

    return maskNumber(phone);
  };

  const channelInfo = () => {
    const channelData = CHANNEL_MAP.get(channel);

    if (!channelData || channelData.display === 'None') {
      switch (currentVerificationChannel) {
        case VerificationChannel.Email:
          return 'email';
        case VerificationChannel.Sms:
          return 'sms';
        case VerificationChannel.WhatsApp:
          return 'whatsapp';
        default:
          return 'whatsapp';
      }
    }

    return channelData.display;
  };

  return `We have sent you the code via ${channelInfo()} to ${getDestination()}`;
};

export const ChangeVerificationBlock = () => {
  const refreshToken = sessionStorage.getItem('refresh_token');

  const [
    activeMethod,
    setActiveMethod,
  ] = useState<string>('change-verification');
  const [
    currentParagraph,
    setCurrentParagraph,
  ] = useState<string>('');
  const { setActiveBlock } = useBlockDisplay();
  const currentVerificationChannel = useSelector<
  { auth: { verificationChannel: VerificationChannel | null, }, }>(
    (state) => state.auth.verificationChannel,
  ) as VerificationChannel | null;
  const [
    selectedMethod,
    setSelectedMethod,
  ] = useState<string | null>(currentVerificationChannel);
  const email = useSelector<
  { auth: { email: string, }, }>(
    (state) => state.auth.email,
  ) as string;
  const phone = useSelector<
  { auth: { phone: string, }, }>(
    (state) => state.auth.phone,
  ) as number;

  const selectMethod = (method: string) => {
    setSelectedMethod(method);
  };

  const changeVerification = () => {
    authApi.changeVerificationMethodFromSettings({
      refreshToken,
      verificationChannel: selectedMethod as VerificationChannel,
    });

    setActiveMethod(selectedMethod as string);
    setCurrentParagraph(getSendToParagraph(selectedMethod as VerificationChannel,
      {
        email,
        phone: phone?.toString() || '',
      },
      currentVerificationChannel as VerificationChannel));
  };

  return <div className='flex h-full flex-col justify-between gap-1 dt:h-auto'>
    {activeMethod === 'change-verification' ? <>
      <div className='flex flex-col  gap-1'>
        <div>
          <p className='m-0 text-title-3 font-medium dt:text-font-1'>Change your verification method</p>
          <p className='m-0 text-font-2 font-normal text-black-700'>{activeMethod === 'change-verification' ? 'Note that if you change your method, we will need you verify it.' : currentParagraph}</p>
        </div>
        <div className='flex flex-col gap-[0.5rem]'>
          <VerificationMethodCard dataTest='whatsapp-verification-card' description='Fastest, most reliable method' disabled={!phone} iconId='whatsapp' isSelected={selectedMethod === 'whatsapp'} methodText='Whatsapp' onSelect={() => selectMethod('whatsapp')} />
          <VerificationMethodCard dataTest='sms-verification-card' description='Receive codes via SMS' disabled={!phone} iconId='sms' isSelected={selectedMethod === 'sms'} methodText='SMS' onSelect={() => selectMethod('sms')} />
          <VerificationMethodCard dataTest='email-verification-card' description='Receive codes via email' iconId='email' isSelected={selectedMethod === 'email'} methodText='Email' onSelect={() => selectMethod('email')} />
          <VerificationMethodCard dataTest='none-verification-card' description='Least secure option' iconId='none' isSelected={selectedMethod === 'none'} methodText='No 2FA verification' onSelect={() => selectMethod('none')} />
        </div>
      </div>
      <ButtonArea buttonText='Next' disabled={selectedMethod === currentVerificationChannel} onCancel={() => setActiveBlock('edit-fields')} onSave={changeVerification} />
    </>
      : <div className='flex h-full flex-col gap-8'>
        <div className='flex flex-col gap-[0.25rem]'>
          <p className='m-0 !text-title-3 font-medium dt:!text-font-1'>Change your verification method</p>
          <p className='m-0 text-font-2 font-normal text-black-700'>{currentParagraph}</p>
        </div>
        <ChangePhoneNumberContextProvider>
          <ConfirmBlock
            isChangingVerification
            selectedMethod={selectedMethod as string}
            setActiveMethod={setActiveMethod}
          />
        </ChangePhoneNumberContextProvider>
      </div>
    }
  </div>;
};
