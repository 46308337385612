import {
  type DocumentPeriodsFilter,
  documentsApi,
  type DocumentsFilter } from '../../api/documentsApi';
import {
  type DocumentCategory,
  type DocumentDTO,
  type DocumentGroupType,
  type IReportGroup,
  removeItemsFromStorage,
} from '../../types';
import { type PeriodFilters } from './filtersSlice';
import { createAsyncThunk,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit';
import { type SelectOption } from 'hooks';

const readFiltersFromSessionStorage = (readFrom: 'displayType') => {
  const stringifiedFilter = sessionStorage.getItem(readFrom);

  if (!stringifiedFilter) {
    return null;
  }

  const parsedFilters = {display: stringifiedFilter,
    id: stringifiedFilter} as SelectOption;
  return parsedFilters;
};

export type DocumentsPendingState = {
  categoryId: string,
  documentGroupId: string,
  error: string | null,
  fulfilled: boolean,
  isNewDocumentsCategory: boolean,
  pending: boolean,
};

export type Pagination = {
  page: number,
  size: number,
};

export type DocumentsStoreState = {
  categoriesError: string | null,
  categoriesPending: boolean,
  displayType: SelectOption,
  documentsError: string | null,
  documentsFetchQueue: DocumentsPendingState[],
  documentsPending: DocumentsPendingState,
  latestDocuments: DocumentDTO[] | null,
  newCategory: DocumentCategory | null,
  otherCategories: DocumentCategory[] | null,
  pagination: Pagination,
  reports: IReportGroup[] | null,
  reportsError: string | null,
  reportsPending: boolean,
};

const initialState: DocumentsStoreState = {
  categoriesError: null,
  categoriesPending: false,
  displayType: readFiltersFromSessionStorage('displayType') as SelectOption,
  documentsError: null,
  documentsFetchQueue: [],
  documentsPending: {
    categoryId: '',
    documentGroupId: '',
    error: null,
    fulfilled: false,
    isNewDocumentsCategory: false,
    pending: false,
  },
  latestDocuments: null,
  newCategory: null,
  otherCategories: null,
  pagination: {
    page: 1,
    size: 10,
  },
  reports: null,
  reportsError: null,
  reportsPending: false,
};

export const fetchCategories = createAsyncThunk(
  'documents/fetchCategories',
  async (filter: DocumentPeriodsFilter) => {
    const { accountIds,
      displayType,
      firmId,
      fundIds,
      quarters } = filter;
    const categories = await documentsApi.getDocumentCategories({
      accountIds,
      displayType,
      firmId,
      fundIds,
      quarters,
    });
    return categories;
  },
);

type FetchDocumentsParameters = {
  addToQueue?: boolean,
  categoryId?: string,
  fileType?: string,
  filter: DocumentsFilter,
  page?: number,
  periodFilter?: PeriodFilters,
  size?: number,
};
export const fetchDocuments = createAsyncThunk(
  'documents/fetchDocuments',
  async ({ categoryId,
    fileType,
    filter,
    periodFilter }: FetchDocumentsParameters) => {
    const documents = await documentsApi.getDocuments(
      categoryId as string,
      fileType as string,
      {...filter,
        ...periodFilter},
    );
    return documents;
  },
);

export const fetchLatestDocuments = createAsyncThunk(
  'documents/fetchLatestDocuments',
  async ({
    filter,
    page,
    size}: FetchDocumentsParameters) => {
    const documents = await documentsApi.getLatestDocuments(
      filter,
      page as number,
      size as number,
    );
    return documents;
  },
);

type FetchReportsParameters = {
  firmId: string,
  fundIds: string[],
};
export const fetchReports = createAsyncThunk(
  'documents/fetchReports',
  async (filter: FetchReportsParameters) => {
    const reports = await documentsApi.getReports(filter);
    return reports;
  },
);

const documentsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.categoriesPending = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categoriesPending = false;
        const allCategories = action.payload.map((category, categoryIndex) => ({
          ...category,
          documentTypeGroups: category.documentTypeGroups.map((group, groupIndex) => ({
            ...group,
            documents: null,
            isOpen:
              state.displayType.id === 'period' &&
              categoryIndex === 1 &&
              groupIndex === 0,
          })),
          isOpen: state.displayType.id === 'period' ? categoryIndex < 3 : true,
        }));

        const newCategory = allCategories
          .find((category) => category.isNewDocumentsCategory) || null;

        if (newCategory) {
          newCategory.isOpen = true;
        }

        const otherCategories = allCategories
          .filter((category) => !category.isNewDocumentsCategory);

        state.newCategory = newCategory;
        state.otherCategories = otherCategories;
      })
      .addCase(fetchCategories.rejected, (state) => {
        state.categoriesPending = false;
        state.categoriesError = 'Could not fetch categories';
      })
      .addCase(fetchDocuments.pending, (state, action) => {
        const {
          addToQueue,
          categoryId,
          fileType,
          filter,
        } = action.meta.arg;

        if (addToQueue) {
          state.documentsFetchQueue.push({
            categoryId: categoryId as string,
            documentGroupId: fileType as string,
            error: null,
            fulfilled: false,
            isNewDocumentsCategory: filter.filterByNewDocuments,
            pending: true,
          });
        } else {
          const foundItem = state.documentsFetchQueue.find((item) => {
            return item.categoryId === categoryId &&
              item.documentGroupId === fileType &&
              item.isNewDocumentsCategory === filter.filterByNewDocuments;
          });

          if (foundItem) {
            foundItem.error = null;
            foundItem.fulfilled = false;
            foundItem.pending = true;
          }
        }
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        const documents = action.payload;
        const {
          categoryId,
          fileType,
          filter,
        } = action.meta.arg;

        const { filterByNewDocuments } = filter;
        const {
          newCategory,
          otherCategories,
        } = state;

        let foundFileTypeGroup: DocumentGroupType | null = null;

        if (filterByNewDocuments && newCategory) {
          foundFileTypeGroup = newCategory.documentTypeGroups
            .find(({
              categoryId: catId,
              documentGroupId,
            }) => catId === categoryId && documentGroupId === fileType)
            || null;
        } else if (!filterByNewDocuments && otherCategories) {
          foundFileTypeGroup = otherCategories
            .flatMap((category) => category.documentTypeGroups)
            .find(({
              categoryId: catId,
              documentGroupId,
            }) => catId === categoryId && documentGroupId === fileType)
            || null;
        }

        if (foundFileTypeGroup) {
          foundFileTypeGroup.documents = documents;
        }

        // Pop item from queue since we do not need to fetch
        state.documentsFetchQueue = state.documentsFetchQueue.filter((item) => {
          return item.categoryId !== categoryId ||
            item.documentGroupId !== fileType ||
            item.isNewDocumentsCategory !== filterByNewDocuments;
        });

        return state;
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        const {
          categoryId,
          fileType,
          filter,
        } = action.meta.arg;
        const { filterByNewDocuments } = filter;

        const foundItem = state.documentsFetchQueue.find((item) => {
          return item.categoryId === categoryId &&
            item.documentGroupId === fileType &&
            item.isNewDocumentsCategory === filterByNewDocuments;
        });

        if (foundItem) {
          foundItem.error = `Could not fetch documents.
          Please try again for type ${fileType}.`;
          foundItem.pending = false;
        }
      })
      .addCase(fetchReports.pending, (state) => {
        state.reportsPending = true;
      })
      .addCase(fetchReports.fulfilled, (state, action) => {
        state.reportsPending = false;
        state.reports = action.payload;
      })
      .addCase(fetchReports.rejected, (state) => {
        state.reportsPending = false;
        state.reportsError = 'Could not fetch reports.';
      })
      .addCase(fetchLatestDocuments.pending, (state) => {
        state.documentsPending.pending = true;
      })
      .addCase(fetchLatestDocuments.fulfilled, (state, action) => {
        state.documentsPending.pending = false;
        state.latestDocuments = [
          ...state.latestDocuments || [],
          ...action.payload,
        ];
      })
      .addCase(fetchLatestDocuments.rejected, (state) => {
        state.documentsPending.pending = false;
        state.documentsPending.error = 'Could not fetch documents.';
      });
  },
  initialState,
  name: 'documents',
  reducers: {
    markDocumentsAsSeen: (
      state,
      action: PayloadAction<{
        categoryId: string,
        documentGroupId: string,
        documentIds: string[],
      }>,
    ) => {
      const {
        categoryId,
        documentGroupId,
        documentIds,
      } = action.payload;
      const {
        latestDocuments,
        otherCategories,
      } = state;

      if (categoryId === 'New Documents Category' && latestDocuments && otherCategories) {
        const filterdNewDocs = latestDocuments
          .filter((document) => documentIds.includes(document._id));
        for (const docc of filterdNewDocs) {
          if (documentIds.includes(docc._id)) {
            docc.userViewed = true;
          }

          for (const category of otherCategories) {
            const group = category.documentTypeGroups.find((typeGroup) =>
              typeGroup.documentGroupId === docc.fileType);
            if (group) {
              group.numberOfNewDocuments -= 1;
              const otherCategorydoc = group.documents?.find((item) =>
                item._id === documentIds[0] && !item.userViewed);
              if (otherCategorydoc) {
                otherCategorydoc.userViewed = true;
              }

              break;
            }
          }
        }
      }

      if (otherCategories) {
        const filteredDocumentsOthers = otherCategories.filter(
          (category) => {
            return category.categoryId.toString() === categoryId.toString();
          },
        )
          .flatMap((category) => category.documentTypeGroups)
          .filter(
            (documentGroup) => documentGroup.documentGroupId ===
              documentGroupId,
          )
          .flatMap((documentGroup) => documentGroup.documents);

        if (latestDocuments) {
          for (const document of latestDocuments) {
            if (documentIds.includes(document._id)) {
              document.userViewed = true;
            }
          }
        }

        const group = otherCategories
          ?.find((category) => category.categoryId.toString() === categoryId.toString())
          ?.documentTypeGroups
          .find((typeGroup) => typeGroup.documentGroupId === documentGroupId);

        if (group) {
          group.numberOfNewDocuments -= documentIds.length;
        }

        for (const document of filteredDocumentsOthers) {
          if (document && documentIds.includes(document._id)) {
            document.userViewed = true;
          }
        }
      }

      return state;
    },
    resetDocuments: () => {
      removeItemsFromStorage([
        'displayType',
      ], sessionStorage);

      return { ...initialState };
    },
    resetLatestDocuments: (
      state,
    ) => {
      state.latestDocuments = null;
      state.pagination = {
        page: 1,
        size: 10,
      };
    },
    setDisplayType: (
      state,
      action,
    ) => {
      state.displayType = action.payload;
    },
    setPagination: (state, action) => {
      const { payload } = action;
      state.pagination = payload;
    },
    toggleCategory: (
      state,
      action: PayloadAction<{
        isNewDocumentsCategory: boolean,
        isOpen: boolean,
        topLevelCategoryId: string,
      }>,
    ) => {
      const {
        isNewDocumentsCategory,
        isOpen,
        topLevelCategoryId,
      } = action.payload;
      const {
        newCategory,
        otherCategories,
      } = state;

      if (isNewDocumentsCategory && newCategory) {
        newCategory.isOpen = isOpen;
      } else if (!isNewDocumentsCategory && otherCategories) {
        const foundCategory = otherCategories.find(
          (category) => category.categoryId === topLevelCategoryId &&
            category.isNewDocumentsCategory === isNewDocumentsCategory,
        );

        if (foundCategory) {
          foundCategory.isOpen = isOpen;
        }
      }

      return state;
    },
    toggleDocumentTypeInCategory: (
      state,
      action: PayloadAction<{
        documentGroupId: string,
        isNewDocumentsCategory: boolean,
        isOpen: boolean,
        topLevelCategoryId: string,
      }>,
    ) => {
      const {
        documentGroupId,
        isNewDocumentsCategory,
        isOpen,
        topLevelCategoryId,
      } = action.payload;
      const {
        newCategory,
        otherCategories,
      } = state;

      let foundFileTypeGroup: DocumentGroupType | null = null;

      if (
        isNewDocumentsCategory &&
        newCategory &&
        topLevelCategoryId === newCategory.categoryId
      ) {
        foundFileTypeGroup = newCategory
          .documentTypeGroups
          .find((fileGroup) => fileGroup.documentGroupId === documentGroupId)
          || null;
      } else if (!isNewDocumentsCategory && otherCategories) {
        foundFileTypeGroup = otherCategories.filter(
          (category) => category.categoryId === topLevelCategoryId &&
            category.isNewDocumentsCategory === isNewDocumentsCategory,
        )
          .flatMap((category) => category.documentTypeGroups)
          .find((fileGroup) => fileGroup.documentGroupId === documentGroupId)
          || null;
      }

      if (foundFileTypeGroup) {
        foundFileTypeGroup.isOpen = isOpen;
      }

      return state;
    },
  },
});

const documentsReducer = documentsSlice.reducer;

export const {
  markDocumentsAsSeen,
  resetDocuments,
  resetLatestDocuments,
  setDisplayType,
  setPagination,
  toggleCategory,
  toggleDocumentTypeInCategory,
} = documentsSlice.actions;

export { documentsReducer };
