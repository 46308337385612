import { LoginLogo } from '../LoginLogo/LoginLogo';
import style from './LoginHeader.module.scss';
import { OtherSoftwareSolutions } from 'components/OtherSoftwareSolutions/OtherSoftwareSolutions';
import { isMobile } from 'services/mobile';

const goToServcMainPage = () => {
  window.location.href = 'https://www.orcainc.com/';
};

export const LoginHeader = () => {
  return (
    <header className={style.loginHeader}>
      <div className='flex cursor-pointer items-center gap-1' onClick={goToServcMainPage}>
        <LoginLogo />
        {isMobile() ? null : <span className='text-bold whitespace-nowrap text-[13px] font-medium'>Digital fund administration</span>}
      </div>
      <OtherSoftwareSolutions isMobile={false} />
    </header>
  );
};

