import style from './DocumentCategory.module.scss';
import { Svgicon } from 'components/Svgicon/Svgicon';
import {
  type FC,
} from 'react';

export const EmptyDocumentCategory: FC = () => {
  return (
    <div className={`${style.categoryContainer} !bg-black-200`} >
      <div className='flex w-[17.1875rem] flex-row items-center gap-[0.25rem] p-[1rem]'>
        <div className='size-[1rem] rounded-large bg-black-300' />
        <div className='h-[0.875rem] w-[5rem] rounded-large bg-black-300' />
      </div>
      <div className='flex w-full items-center gap-[0.75rem] p-[1rem]'>
        <Svgicon className='size-7' id='empty-folder' />
        <div className='flex flex-row gap-[0.25rem]'>
          <div className='size-[1rem] rounded-large bg-black-300' />
          <div className='h-[1rem] w-[11.875rem] rounded-large bg-black-300' />
        </div>
      </div>
    </div>
  );
};
