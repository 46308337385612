import loginHeaderStyle from '../LoginHeader/LoginHeader.module.scss';
import classNames from 'classnames';

export const SixCardsSvg = () =>
  <svg
    fill='none'
    height='20'
    viewBox='0 0 20 20'
    width='20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.08333 6.25C2.08333 5.55964 2.64297 5 3.33333 5H4.99999C5.69035 5 6.25 5.55964 6.25 6.25V7.91667C6.25 8.60702 5.69035 9.16667 4.99999 9.16667H3.33333C2.64297 9.16667 2.08333 8.60702 2.08333 7.91667V6.25Z'
      fill='#484747'
      opacity='0.5'
    />
    <path
      d='M2.08333 12.0833C2.08333 11.393 2.64297 10.8333 3.33333 10.8333H4.99999C5.69035 10.8333 6.25 11.393 6.25 12.0833V13.75C6.25 14.4404 5.69035 15 4.99999 15H3.33333C2.64297 15 2.08333 14.4404 2.08333 13.75V12.0833Z'
      fill='#484747'
    />
    <path
      d='M7.91667 6.25C7.91667 5.55964 8.47632 5 9.16667 5H10.8333C11.5237 5 12.0833 5.55964 12.0833 6.25V7.91667C12.0833 8.60702 11.5237 9.16667 10.8333 9.16667H9.16667C8.47632 9.16667 7.91667 8.60702 7.91667 7.91667V6.25Z'
      fill='#484747'
    />
    <path
      d='M7.91667 12.0833C7.91667 11.393 8.47632 10.8333 9.16667 10.8333H10.8333C11.5237 10.8333 12.0833 11.393 12.0833 12.0833V13.75C12.0833 14.4404 11.5237 15 10.8333 15H9.16667C8.47632 15 7.91667 14.4404 7.91667 13.75V12.0833Z'
      fill='#484747'
    />
    <path
      d='M13.75 6.25C13.75 5.55964 14.3096 5 15 5H16.6667C17.357 5 17.9167 5.55964 17.9167 6.25V7.91667C17.9167 8.60702 17.357 9.16667 16.6667 9.16667H15C14.3096 9.16667 13.75 8.60702 13.75 7.91667V6.25Z'
      fill='#484747'
    />
    <path
      d='M13.75 12.0833C13.75 11.393 14.3096 10.8333 15 10.8333H16.6667C17.357 10.8333 17.9167 11.393 17.9167 12.0833V13.75C17.9167 14.4404 17.357 15 16.6667 15H15C14.3096 15 13.75 14.4404 13.75 13.75V12.0833Z'
      fill='#484747'
    />
  </svg>;

export const OtherSoftwareSolutions = ({ isMobile }: { isMobile: boolean, }) => {
  return <div
    className={classNames({
      [loginHeaderStyle['other-software-wrapper']]: isMobile,
      [loginHeaderStyle.mobile]: isMobile,
    })}
  >
    <button
      className={`${loginHeaderStyle.otherSolutions} 
  ${isMobile ? loginHeaderStyle.mobile : loginHeaderStyle.desktop}`}
      onClick={() => {
        window.location.href = 'https://www.orcainc.com/';
      }}
      type='button'
    >
      <SixCardsSvg />
      Other software solutions
    </button>
  </div>;
};
