import { type CapitalAccountFund,
  type SelectedFundAccountPeriodMap } from '../types';
import { Api } from './Api';
import { Alert,
  unauthorized } from './utils';
import { isAxiosError } from 'axios';
import { downloadLinkCreator } from 'helpers/helpers';
import { notificationsService } from 'services';

export type CapitalAccountsFilter = {
  accountIds: string[],
  firmId: string,
};

export type DownloadCapitalAccountsReport = {
  accountIds: string[],
  currentPeriod: SelectedFundAccountPeriodMap,
  firmId: string,
};

const capitalAccountsRequester = new Api('/api/v1/firms');

const checkForCapitalAccounts = async ({ accountIds,
  firmId }: CapitalAccountsFilter) => {
  try {
    const {data} = await capitalAccountsRequester
      .post<{accountIds: string[], firmId: string,},
    { hasAnyCAReports: boolean, }>('/capital-accounts/preview',
      {
        accountIds,
        firmId,
      },
      {});
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not retrieve capital account information');
    }

    throw new Error('Could not retrieve capital account information.');
  }
};

const downloadCapitalAccountsReport = async ({ accountIds,
  currentPeriod,
  firmId }: DownloadCapitalAccountsReport) => {
  try {
    const resp = await capitalAccountsRequester
      .post<{accountIds: string[],
      currentPeriod: SelectedFundAccountPeriodMap, firmId: string,},
    Blob>('/capital-accounts/excel-download',
      {
        accountIds,
        currentPeriod,
        firmId,
      },
      {},
      {},
      false,
      'blob');

    downloadLinkCreator('CapitalAccountsReport.xlsx', resp.data);
  } catch {
    notificationsService.error('Error during download excel');
  }
};

const getCapitalAccounts = async ({ accountIds,
  firmId }: CapitalAccountsFilter) => {
  try {
    const {data} = await capitalAccountsRequester
      .post<{accountIds: string[], firmId: string,},
    {data: CapitalAccountFund[],}>('/capital-accounts',
      {
        accountIds,
        firmId,
      },
      {});
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not retrieve capital account information');
    }

    throw new Error('Could not retrieve capital account information.');
  }
};

export const capitalAccountsApi = {
  checkForCapitalAccounts,
  downloadCapitalAccountsReport,
  getCapitalAccounts,
};
