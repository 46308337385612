import {
  InputIcon,
  LoginInput,
} from '../LoginInput/LoginInput';
import { useState } from 'react';

type Props = {
  autoCompleteField?: string,
  errorMessages?: string[],
  name?: string,
  onChange?: (string_: string) => void,
  placeholder?: string,
  styles?: string,
  value?: string,
};

export const LoginPasswordInput = ({
  autoCompleteField,
  errorMessages,
  name = '',
  onChange,
  placeholder = '',
  styles,
  value = '' }: Props) => {
  const [
    isShow,
    setIsShow,
  ] = useState(false);
  const type = isShow ? 'text' : 'password';
  const onIconClick = () => {
    setIsShow(!isShow);
  };

  return <div className='flex w-full flex-col'>
    <LoginInput
      autoCompleteField={autoCompleteField}
      className={styles}
      icon={isShow ? InputIcon.OpenedEye : InputIcon.Eye}
      name={name}
      onChange={onChange}
      onIconClick={onIconClick}
      placeholder={placeholder}
      type={type}
      value={value}
    />
    {errorMessages && errorMessages.length > 0 &&
      errorMessages.map((error) =>
        <p className='m-0 text-font-3 text-label-r-200' key={error}>{error}</p>,
      )
    }
  </div>;
};
