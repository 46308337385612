import {authActionTypes} from '../actions';
import {getItemsFromStorage} from 'types';

const {
  email,
  exp,
  fullPhoneNumber: phone,
  verificationChannel,
} = getItemsFromStorage([
  'verificationChannel',
  'email',
  'exp',
  'fullPhoneNumber',
], sessionStorage);

const initialState = {
  changePassSendLinkSuccess: false,
  email,
  exp,
  passwordSuccessfullyChanged: false,
  phone,
  verificationChannel,
};

// eslint-disable-next-line default-param-last
export const auth = (state = initialState, {payload,
  type}) => {
  switch (type) {
  case authActionTypes.SET_LOGIN_SUCCESS: {
    const filteredPayload = Object.fromEntries(
      Object.entries(payload || {}).filter(([
        key,
      ]) => ![
        'access_token',
        'refresh_token',
      ].includes(key)),
    );
    return {...state,
      ...filteredPayload};
  }

  case authActionTypes.SET_RESET_PASSWORD_SUCCESS:
    return {...state,
      changePassSendLinkSuccess: payload};
  case authActionTypes.CHANGE_PHONE_NUMBER:
    return {...state,
      phone: payload.replace('+', '')};
  case authActionTypes.CHANGE_EMAIL:
    return {...state,
      email: payload};
  case authActionTypes.PASSWORD_SUCCESSFULLY_CHANGED:
    return {...state,
      passwordSuccessfullyChanged: payload};
  case authActionTypes.RESET:
    return {...initialState};
  default:
    return state;
  }
};
