import './Dropdown.scss';
// import ChevronDown from '../../assets/icons/global/dropdown-chevron.svg?react';
import ChevronDownBlue from '../../assets/icons/global/dropdown-chevron-blue.svg?react';
import { GlobalElementBusContext } from '../../contexts';
import optionStyle from './DropdownOption/DropdownOption.module.scss';
import { DropdownOptionList } from './DropdownOptionList';
import { Input } from 'antd';
import classNames from 'classnames';
import { Svgicon } from 'components/Svgicon/Svgicon';
import {
  type SelectDisplayOption,
  type SelectOption,
  useModalPosition,
  useSingleSelector } from 'hooks';
import React, {
  type ChangeEvent,
  createRef,
  type CSSProperties,
  type FC,
  useContext,
  useEffect,
  useRef,
  useState} from 'react';

type DropdownProps = {
  allowOthersOption?: boolean,
  className?: string,
  closeSignal?: boolean,
  dataSelectionValue?: string,
  displayBackgroundColor?: string,
  displayColor?: string,
  displayFontWeight?: string,
  displayHeight?: string,
  displaySize?: string,
  optionContainerStyle?: string,
  optionList: SelectOption[],
  optionTextStyle?: string,
  optionsClassName?: string,
  placeholder: string,
  rounded?: boolean,
  selectedOptionContainerStyle?: string,
  setValue: (newValue: SelectOption) => void,
  style?: CSSProperties,
  title?: string,
  type?:
  'gray-blue' | 'gray' | 'transparent' | 'white',
  value: SelectOption,
  withAltDesign?: boolean,
};

const OTHERS_OPTION = {
  display: 'Others',
  id: 'Others',
};

const Dropdown: FC<DropdownProps> = ({ allowOthersOption = false,
  className,
  closeSignal = false,
  dataSelectionValue = 'dropdown',
  displayBackgroundColor,
  displayColor,
  displayFontWeight,
  displayHeight,
  displaySize,
  optionContainerStyle,
  optionList,
  optionTextStyle,
  optionsClassName,
  placeholder,
  rounded = false,
  selectedOptionContainerStyle,
  setValue,
  style: inputStyle,
  title,
  type,
  value,
  withAltDesign = false,
}) => {
  const {
    findClosestAncestor,
    version,
  } = useContext(GlobalElementBusContext);
  const [
    focused,
    setFocused,
  ] = useState(false);
  const [
    inputValue,
    setInputValue,
  ] = useState('');
  const [
    activateOthers,
    setActivateOthers,
  ] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [
    refToTrack,
    setRefToTrack,
  ] = useState(createRef<HTMLElement | SVGSVGElement>());
  const newOptionList = allowOthersOption ? [
    ...optionList,
    OTHERS_OPTION,
  ] : [
    ...optionList,
  ];
  const { markOption,
    options,
    setNewOptions } = useSingleSelector({ optionList: newOptionList });
  const optionsPosition = useModalPosition(focused, ref, refToTrack);

  useEffect(() => {
    const brandNewOptionList = allowOthersOption ? [
      ...optionList,
      OTHERS_OPTION,
    ] : [
      ...optionList,
    ];
    setNewOptions(brandNewOptionList);
  }, [
    allowOthersOption,
    optionList,
    setNewOptions,
  ]);

  useEffect(() => {
    if (ref.current) {
      const closestAncestor = findClosestAncestor(ref);
      if (closestAncestor) {
        setRefToTrack(closestAncestor);
      }
    }
  }, [
    findClosestAncestor,
    version,
  ]);

  useEffect(() => {
    if (closeSignal && focused) {
      setFocused(false);
    }
  }, [
    closeSignal,
    focused,
    optionsPosition.top,
  ]);

  const toggleFocus = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.target instanceof HTMLElement &&
      ref.current?.contains(event.target)) {
      setFocused(!focused);
    }
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!allowOthersOption) {
      return;
    }

    const newInputValue = event.target.value;
    setInputValue(newInputValue);
  };

  const onInputButtonClick = () => {
    setActivateOthers(false);
    setValue({
      ...OTHERS_OPTION,
      display: inputValue,
    });
    setInputValue('');
    setFocused(false);
  };

  const onClickOption = (isOthersOption: boolean) =>
    (optionValue: SelectDisplayOption) =>
      (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        markOption(optionValue.id);
        setValue({
          display: optionValue.name,
          id: optionValue.id,
        });

        setInputValue('');
        if (isOthersOption) {
          return setActivateOthers(true);
        }

        return setFocused(false);
      };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (ref.current &&
        !ref.current?.contains(target) &&
        !target.closest(`.${optionStyle.option}`)) {
        setFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [
    ref,
  ]);

  return (
    <div
      className={[
        'dropdownContainer',
        className,
      ].filter(Boolean).join(' ')}
      data-test={`${dataSelectionValue}-container`}
      onClick={toggleFocus}
      ref={ref}
      style={inputStyle}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <div
        className='flex h-full cursor-pointer flex-col items-start gap-0.125'
        data-test={`${dataSelectionValue}-container`}
        id={value.id}
      >
        {
          title && <div className='text-font-3 font-medium'>
            {title}
          </div>
        }
        <div className='selectedValue' >
          <Input
            className={classNames(withAltDesign ? 'gray-blue' : 'gray', { '!rounded-large': rounded }, `${type}`)}
            placeholder={placeholder}
            style={{
              backgroundColor: displayBackgroundColor,
              color: displayColor,
              fontSize: displaySize,
              fontWeight: displayFontWeight,
              height: displayHeight,
            }}
            suffix={withAltDesign ? <ChevronDownBlue /> : <Svgicon className='size-1' color='bg-black-100' id='UP-arrow-down' />}
            type='text'
            value={value.display}
            width='auto'
          />
        </div>
      </div>
      <DropdownOptionList
        activateOthers={activateOthers}
        allowOthersOption={allowOthersOption}
        dataSelectionValue={dataSelectionValue}
        focused={focused}
        inputValue={inputValue}
        onClickOption={onClickOption}
        onInputButtonClick={onInputButtonClick}
        onInputChange={onInputChange}
        optionContainerStyle={optionContainerStyle}
        optionTextStyle={optionTextStyle}
        options={options}
        optionsClassName={optionsClassName}
        selectedOptionContainerStyle={selectedOptionContainerStyle}
      />
    </div>
  );
};

export default Dropdown;
