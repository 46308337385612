import './CapitalAccountsBanner.scss';
import {type CapitalAccountsStoreState,
  type DocumentsStoreState } from '../../../store/slices';
import { EmptyStateCard } from 'components/EmptyStateBanner/EmptyStateBanner';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import {
  useRef} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const CapitalAccountsBanner = () => {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    otherCategories,
  } = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents,
  ) as DocumentsStoreState;
  const {
    hasCapitalAccounts,
  } = useSelector<{ capitalAccounts: CapitalAccountsStoreState, }>(
    (state) => state.capitalAccounts,
  ) as CapitalAccountsStoreState;

  const onViewButtonClick = () => navigate('/firm/capital-accounts');

  if (!otherCategories?.length) {
    return <EmptyStateCard
      containerClass='capital-accounts'
      dataTest='view-capital-accounts-button'
      description='Your investment overview'
      hasItems={hasCapitalAccounts}
      onViewClick={onViewButtonClick}
      title='Capital accounts'
    />;
  }

  return (
    <div
      className='container flex max-h-[7.375rem] w-full max-w-[90rem] justify-between rounded-main p-[0.875rem]'
      ref={containerRef}
    >
      <div className='flex w-full flex-col justify-between'>
        <h3 className='truncate text-font-1 font-medium'>
          Capital accounts
        </h3>
        <Button
          className='!h-8 w-[5.5rem]'
          data-test='view-capital-accounts-button'
          onClick={onViewButtonClick}
          size='medium'
          tooltipWrapperClass='!justify-start'
          type='total-black'
        >
          View
        </Button>
      </div>
    </div>
  );
};
