/* eslint-disable max-len */
import style from './ForgotPassword.module.scss';
import authApi from 'api/auth';
import { actionCreator } from 'api/utils';
import { isAxiosError } from 'axios';
import { LoginBackLink,
  LoginButton,
  LoginHeading,
  LoginInput,
  LoginParagraph } from 'components';
import { useAuthCheck,
  useDocumentTitle } from 'hooks';
import React, { useCallback,
  useEffect,
  useRef,
  useState } from 'react';
import Recaptcha from 'react-google-recaptcha';
import { useDispatch,
  useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RECAPTCHA } from 'resources/constants';
import { notificationsService } from 'services';
import { messages } from 'services/notifications';
import { globalActionTypes } from 'store/actions';
import { getItemsFromStorage } from 'types';
import isEmail from 'validator/lib/isEmail';

export type ForgotPasswordPayload = {
  email: string,
  recaptchaV2Token?: string,
  recaptchaV3Token: string,
};

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [
    email,
    setEmail,
  ] = useState(getItemsFromStorage<{emailForResetPassword: string, }>([
    'emailForResetPassword',
  ], sessionStorage).emailForResetPassword || '');
  const [
    showCaptchaV2,
    setShowCaptchaV2,
  ] = useState(false);
  const captchaRef = useRef<Recaptcha>(null);
  const [
    isExecutingRecaptcha,
    setIsExecutingRecaptcha,
  ] = useState(false);

  // @ts-expect-error state not typed :(
  const isLoading = useSelector((state) => state.global.loading) as boolean;

  useAuthCheck();
  useDocumentTitle('Forgot password');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA.V3_SITE_KEY}`;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const executeRecaptcha = useCallback(async () => {
    setIsExecutingRecaptcha(true);
    try {
      await window.grecaptcha.ready(() => {});
      return await window.grecaptcha.execute(RECAPTCHA.V3_SITE_KEY, { action: 'forgotPassword' });
    } finally {
      setIsExecutingRecaptcha(false);
    }
  }, []);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isEmail(email)) {
      notificationsService.error(messages.wrongEmail);
      return;
    }

    dispatch(actionCreator(globalActionTypes.LOADING, true));

    try {
      const recaptchaV3Token = await executeRecaptcha();
      const payload: ForgotPasswordPayload = {
        email,
        recaptchaV3Token,
      };

      if (showCaptchaV2) {
        const recaptchaV2Token = captchaRef.current?.getValue();
        if (!recaptchaV2Token) {
          notificationsService.error('Please complete the captcha');
          return;
        }

        payload.recaptchaV2Token = recaptchaV2Token;
      }

      await authApi.forgotPassword(payload);
      notificationsService.success(messages.resetPasswordSuccess);
      navigate('/auth/login');
    } catch (error) {
      if (isAxiosError(error)) {
        const errorMessage = error.response?.data.message;
        if (errorMessage === RECAPTCHA.ERRORS.SCORE_TOO_LOW) {
          setShowCaptchaV2(true);
          throw error;
        }
      }

      notificationsService.error(messages.standartError);
    } finally {
      dispatch(actionCreator(globalActionTypes.LOADING, false));
    }
  };

  return (
    <div className={style.loginWrapper}>
      <div className='flex flex-col gap-[0.5rem]'>
        <LoginBackLink />
        <div className='flex flex-col gap-[0.5rem]'>
          <LoginHeading>Get a one-time link</LoginHeading>
          <LoginParagraph>
            For the security purposes one-time link will expire after 2 hours.
          </LoginParagraph>
        </div>
      </div>
      <form onSubmit={onSubmit}>
        <div className={style.inputsWrapper}>
          <LoginInput
            name='forgot-password-username-input'
            onChange={setEmail}
            placeholder='Email'
            type='email'
            value={email}
          />
          {showCaptchaV2 &&
            <Recaptcha
              className='mb-1'
              ref={captchaRef}
              sitekey={RECAPTCHA.V2_SITE_KEY}
            />
          }
        </div>
        <LoginButton
          isLoading={isLoading || isExecutingRecaptcha}
          name='forgot-password-button'
          text='Send'
          type='submit'
        />
      </form>
    </div>
  );
};
