import { Link } from 'react-router-dom';

type Props = {
  children: React.ReactNode,
  to: string,
};

export const LoginLink = ({
  children,
  to,
}: Props) =>
  <Link
    className='self-center text-font-1 !text-label-g-300 underline
    hover:text-label-g-300 dt:self-start dt:text-font-2' to={to}
  >
    {children}
  </Link>;
