import VARIABLES from '../styles/variables';

export const inputHighlight = (event) => {
  if (event?.target?.value?.length) {
    event.target.parentElement.style.border =
      `2px solid ${VARIABLES.borderColorActive}`;
    event.target.parentElement.style.borderRadius = '3px';
  } else {
    event.target.parentElement.style.border =
      `2px solid ${VARIABLES.borderColor}`;
  }
};

export const removeSpaces = (event) => {
  if (event.keyCode === 32) {
    event.preventDefault();
  }
};

export const getLogoWidth = (size) => {
  if (size === 'Small') {
    return '7rem';
  }

  if (size === 'Medium') {
    return '10rem';
  }

  return '13rem';
};
