import { Checkbox } from 'components/InteractiveUIControls/Checkbox/Checkbox';
import { Tooltip } from 'components/InteractiveUIControls/Tooltip/Tooltip';
import { Svgicon } from 'components/Svgicon/Svgicon';
import { type FC } from 'react';

type VerificationMethodCardProps = {
  dataTest: string,
  description: string,
  disabled?: boolean,
  iconClassname?: string,
  iconId: string,
  isSelected: boolean,
  methodText: string,
  onSelect: () => void,
};
export const VerificationMethodCard: FC<VerificationMethodCardProps> = ({ dataTest,
  description,
  disabled,
  iconClassname,
  iconId,
  isSelected,
  methodText,
  onSelect }) => {
  const handleSelectMethod = () => {
    if (disabled) {
      return;
    }

    onSelect();
  };

  return <div className='tooltip-wrapper'>
    <div className={`${isSelected ? 'border-main-400 bg-main-50' : 'border-black-300'} ${disabled && 'cursor-default opacity-60'} tooltip-wrapper flex w-full cursor-pointer rounded-main border p-[0.5rem]`} data-test={dataTest} onClick={handleSelectMethod}>
      <div className='flex w-full items-center justify-between'>
        <div className='flex  gap-[0.75rem]'>
          <div><Svgicon className={iconClassname} id={iconId} /></div>
          <div className='flex flex-col'>
            <p className='m-0 text-font-1 font-medium leading-6'>{methodText}</p>
            <p className='m-0 text-font-2 font-normal text-black-700'>{description}</p>
          </div>
        </div>
        <div className='flex self-start'>
          <Checkbox checkboxClassName='!rounded-full' readOnly={disabled} selected={isSelected} />
        </div>
      </div>
    </div>
    {disabled &&
    <Tooltip>Please add a phone number to select this verification method</Tooltip>}
  </div>;
};
