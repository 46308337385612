import { type TableColumnDisplayValues } from '../../../types';
import { CHART_COLORS } from '../../Charts/ChartVariables';
import { HorBarChart } from '../../Charts/HorBarChart/HorBarChart';
import style from '../barChart.module.scss';
import { type FC } from 'react';

export type CommitmentBarChartProps = {
  values: TableColumnDisplayValues,
};

export const CommitmentBarChart: FC<CommitmentBarChartProps> = ({
  values,
}) => {
  const getLowerBar = () => {
    const lowerBar = [
      { legendName: 'Distribution',
        value: values.distribution },
      { legendName: 'Net asset value',
        value: values.nav },
    ];

    if (values.distributionWth !== 0) {
      lowerBar.splice(1, 0, { legendName: 'WHT',
        value: values.distributionWth });
    }

    return lowerBar;
  };

  return <div className={style.container}>
    <HorBarChart
      colorList={CHART_COLORS.commitmentBarChart}
      lowerBar={getLowerBar()}
      upperBar={[
        { legendName: 'Net Contribution',
          value: values.contribution + values.contributionDue },
      ]}
    />
  </div>;
};
