import './PdfJsReader.scss';
import 'pdfjs-dist/web/pdf_viewer.css';
import { Spinner } from 'components/Spinner';
import * as pdfjsLib from 'pdfjs-dist';
// eslint-disable-next-line import/extensions
import PDFJSWorkerUrl from 'pdfjs-dist/build/pdf.worker?url';
import { TextLayerBuilder } from 'pdfjs-dist/web/pdf_viewer.mjs';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { isLandscape } from 'services/mobile';

pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorkerUrl;

type PdfJsReaderProps = {
  url: string,
};

// Define Row outside of PdfJsReader to prevent re-creation on each render
type RowProps = {
  index: number,
  renderPage: (
    pageNum: number,
    canvasRef: React.RefObject<HTMLCanvasElement>,
    textLayerRef: React.RefObject<HTMLDivElement>,
  ) => Promise<void>,
  style: React.CSSProperties,
};

const PAGE_GAP = 20;
const RENDER_SCALE = 1.5;
const PIXEL_RATIO = window.devicePixelRatio || 1;
const getDisplayScale = () => isLandscape() ? 1.5 : 0.75;

const Row: React.FC<RowProps> = ({
  index,
  renderPage,
  style,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const textLayerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    renderPage(index + 1, canvasRef, textLayerRef);
  }, [
    index,
    renderPage,
  ]);

  return (
    <div
      className='relative flex items-center justify-center overflow-hidden bg-grey-100'
      style={{
        ...style,
        paddingBottom: PAGE_GAP / 2,
        paddingTop: PAGE_GAP / 2,
      }}
    >
      <canvas className='bg-white' ref={canvasRef} />
      <div className='textLayer absolute' ref={textLayerRef} />
    </div>
  );
};

export const PdfJsReader: React.FC<PdfJsReaderProps> = ({ url }) => {
  const [
    pdf,
    setPdf,
  ] = useState<pdfjsLib.PDFDocumentProxy | null>(null);
  const [
    itemSize,
    setItemSize,
  ] = useState<number>(0);
  const listRef = useRef<List>(null);
  const [
    errorState,
    setErrorState,
  ] = useState<boolean>(false);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        const loadingTask = pdfjsLib.getDocument({
          url,
          withCredentials: true,
        });
        const loadedPdf = await loadingTask.promise;
        setPdf(loadedPdf);
      } catch {
        setErrorState(true);
      }
    };

    loadPdf();
  }, [
    url,
  ]);

  useEffect(() => {
    const calculateItemSize = async () => {
      if (!pdf) {
        return;
      }

      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: RENDER_SCALE });
      const displayScale = getDisplayScale();
      const height = viewport.height * (displayScale / RENDER_SCALE);
      // Add gap to the item size
      setItemSize(height + PAGE_GAP);
    };

    calculateItemSize();
  }, [
    pdf,
  ]);

  const setCanvasDimensions = useCallback((
    canvas: HTMLCanvasElement,
    viewport: pdfjsLib.PageViewport,
  ) => {
    const displayScale = getDisplayScale();
    canvas.width = viewport.width * PIXEL_RATIO;
    canvas.height = viewport.height * PIXEL_RATIO;
    canvas.style.width = `${viewport.width * (displayScale / RENDER_SCALE)}px`;
    canvas.style.height = `${viewport.height * (displayScale / RENDER_SCALE)}px`;
  }, []);

  const renderPage = useCallback(
    async (
      pageNum: number,
      canvasRef: React.RefObject<HTMLCanvasElement>,
      textLayerRef: React.RefObject<HTMLDivElement>,
    ) => {
      if (!pdf || !canvasRef.current || !textLayerRef.current) {
        return;
      }

      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: RENDER_SCALE });
      const canvas = canvasRef.current;
      const textLayerDiv = textLayerRef.current;

      setCanvasDimensions(canvas, viewport);

      const context = canvas.getContext('2d');
      if (!context) {
        return;
      }

      context.scale(PIXEL_RATIO, PIXEL_RATIO);
      await page.render({ canvasContext: context,
        viewport }).promise;

      while (textLayerDiv.firstChild) {
        textLayerDiv.removeChild(textLayerDiv.firstChild);
      }

      const canvasOffset = canvas.offsetLeft;
      const canvasTop = canvas.offsetTop;

      // Configure text layer
      textLayerDiv.style.width = `${viewport.width}px`;
      textLayerDiv.style.height = `${viewport.height}px`;
      textLayerDiv.style.left = `${canvasOffset}px`;
      textLayerDiv.style.top = `${canvasTop}px`;

      // PDF.js uses --scale-factor CSS variable internally to calculate text positioning and sizing.
      // This ensures text elements are properly scaled to match the PDF's internal dimensions.
      // We tried to pass it to "TextLayerBuilder" as an option but we couldn't find a proper way as it's either it's poorly documented or we just missed.
      // As a solution we need to change the global css variable that this lib uses (--scale-factor).
      // For now we just override it here via textLayerDiv element
      textLayerDiv.style.setProperty('--scale-factor', getDisplayScale().toString());

      const textContent = await page.getTextContent();
      const textLayerBuilder = new TextLayerBuilder({
        pdfPage: page,
      });

      await textLayerBuilder.render(viewport, { textContent });
      textLayerDiv.appendChild(textLayerBuilder.div);
    },
    [
      pdf,
      setCanvasDimensions,
    ],
  );

  if (errorState) {
    return (
      <div className='flex size-full items-center justify-center text-title-1'>
        Unable to load PDF
      </div>
    );
  }

  return (
    <div
      style={{
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
      }}
    >
      {pdf && itemSize ?
        <AutoSizer>
          {({
            height,
            width,
          }: {
            // eslint-disable-next-line react/no-unused-prop-types
            height: number,
            // eslint-disable-next-line react/no-unused-prop-types
            width: number,
          }) =>
            <List
              height={height}
              itemCount={pdf.numPages}
              itemSize={itemSize}
              ref={listRef}
              width={width}
            >
              {({
                index,
                style,
              }: {
                // eslint-disable-next-line react/no-unused-prop-types
                index: number,
                // eslint-disable-next-line react/no-unused-prop-types
                style: React.CSSProperties,

              }) =>
                <Row
                  index={index}
                  renderPage={renderPage}
                  style={style}
                />
              }
            </List>
          }
        </AutoSizer>
        :
        <Spinner />
      }
    </div>
  );
};
