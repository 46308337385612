import { MultiSelector } from '../../MultiSelector';
import {type CSSProperties,
  type FC,
  useEffect,
  useState} from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { filterDocumentsDataByYears,
  type OrganizationsStoreState } from 'store/slices';
import { type NestedOrganization } from 'types';

/**
 * Renders a multiselector component for years
 */
const getAllYears = (data: NestedOrganization | null) => {
  const years = data?.funds.flatMap((fund) => fund.accounts)
    .flatMap((acc) => acc.yearsAndQuarters.map((year) => year.year)) ?? [];

  return [
    ...new Set(years),
  ];
};

const getAvailableYears = (data: NestedOrganization | null, accountIds: string[]) => {
  const years = data?.funds.flatMap((fund) => fund.accounts)
    .flatMap((acc) => accountIds
      .includes(acc.accountId) ? acc.yearsAndQuarters
        .map((year) => year.year) : []) ?? [];

  return [
    ...new Set(years),
  ];
};

const getOptions = (data: NestedOrganization | null, accountIds?: string[]) => {
  const years = accountIds ? getAvailableYears(data, accountIds) : getAllYears(data);
  const yearOption = years.map((year) => {
    return {display: String(year),
      id: String(year),
    };
  }).sort((a, b) => b.display.localeCompare(a.display));

  return yearOption;
};

const getAccountsIds = (data: NestedOrganization | null) => {
  return data?.funds.flatMap((fund) => fund.accounts.map((acc) => acc.accountId)) ?? [];
};

const YearSelector: FC<SelectorProps> = ({activeDocumentGroupName,
  style}: SelectorProps) => {
  const dispatch = useDispatch();
  const {
    displayedOrganizationWithPermissions,
    selectedDocumentsData } = useSelector<{
    organizations: OrganizationsStoreState,
  }>((state) => state.organizations) as OrganizationsStoreState;

  const [
    yearOptions,
    setYearOptions,
  ] = useState(getOptions(displayedOrganizationWithPermissions as NestedOrganization));

  const [
    selectedYearIds,
    setSelectedYearIds,
  ] = useState(activeDocumentGroupName ?
    [
      activeDocumentGroupName.toString(),
    ]
    : yearOptions.map((opt) => opt.id));

  const [
    selectedAccIds,
    setSelectedAccIds,
  ] = useState(getAccountsIds(
    displayedOrganizationWithPermissions as NestedOrganization));

  useEffect(() => {
    if (selectedDocumentsData) {
      const newAccIds = getAccountsIds(selectedDocumentsData);
      if (newAccIds.length !== selectedAccIds.length) {
        setSelectedAccIds(newAccIds);
        const newOptions =
        getOptions(displayedOrganizationWithPermissions as NestedOrganization, newAccIds);
        setYearOptions(newOptions);
        if (activeDocumentGroupName) {
          const activeOption = newOptions
            .find((opt) => opt.id === activeDocumentGroupName);
          setSelectedYearIds([
            activeOption?.id as string,
          ]);
        } else {
          setSelectedYearIds(newOptions.map((opt) => opt.id));
        }
      }
    }
  }, [
    activeDocumentGroupName,
    selectedDocumentsData,
  ]);

  const checkYear = (yearIds: string[]) => {
    dispatch(filterDocumentsDataByYears(yearIds));
    setSelectedYearIds(yearIds);
  };

  return (
    <MultiSelector
      dataSelectionValue='download-files-select-filters-year-multiselector'
      disabled={!yearOptions.length}
      onOption={checkYear}
      optionList={yearOptions}
      placeholder='year'
      selectedIds={selectedYearIds}
      style={style || { width: '250px' }}
      title='Period'
    />
  );
};

export { YearSelector };

export type SelectorProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  activeDocumentGroupName?: string | null,
  style?: CSSProperties,
};
