import './Document.scss';
import { Svgicon } from 'components/Svgicon/Svgicon';
import {
  type FC,
} from 'react';

export const EmptyDocument: FC = () => {
  return (
    <div className='relative flex size-auto w-full max-w-[90rem] rounded-main bg-black-200 p-[1rem] pl-[0.5rem] shadow-doc'>
      <div className='flex w-full flex-row items-start gap-[0.5rem]'>
        <Svgicon className='size-8 min-w-8' id='empty-doc' />
        <div className='flex w-full flex-col gap-[0.375rem]'>
          <div className='flex w-full flex-row gap-[0.25rem]'>
            <div className='size-[1rem] rounded-large bg-black-300' />
            <div className='flex h-[1rem] w-full max-w-[11.875rem] rounded-large bg-black-300' />
          </div>
          <div className='flex w-full flex-row gap-[0.25rem]'>
            <div className='size-[1rem] rounded-large bg-black-300' />
            <div className='flex h-[0.875rem] w-full max-w-[5rem] rounded-large bg-black-300' />
          </div>
        </div>
      </div>
    </div>
  );
};
