import './antd.scss';
import style from './DownloadFilesModal.module.scss';
import { DownloadFilesSelectFilters } from './DownloadFilesSelectFilters';
import classNames from 'classnames';
import { Spinner } from 'components/Spinner';
import { Svgicon } from 'components/Svgicon/Svgicon';
import { ModalActivationContext } from 'contexts';
import { useContext,
  useEffect } from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { isMobile } from 'services/mobile';
import { type DocumentsStoreState,
  filterDocumentsDataByTypes,
  filterDocumentsDataByYears,
  type OrganizationsStoreState,
  resetSelectedDocumentsData,
  selectDocumentsData } from 'store/slices';

const DownloadFilesModal = () => {
  const {
    displayType,
  } = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents,
  ) as DocumentsStoreState;
  const { activeDocumentGroupName,
    toggleDownloadsModal } = useContext(ModalActivationContext);
  const mobile = isMobile();
  const dispatch = useDispatch();

  const organizationsPending = useSelector<{
    organizationsPending: OrganizationsStoreState,
  }>((state) => state.organizationsPending) as OrganizationsStoreState;

  const handleCloseModal = () => {
    toggleDownloadsModal(false, null);
    dispatch(resetSelectedDocumentsData());
  };

  useEffect(() => {
    if (activeDocumentGroupName) {
      dispatch(selectDocumentsData());
      if (displayType.id === 'type') {
        dispatch(filterDocumentsDataByTypes([
          activeDocumentGroupName,
        ]));
      } else {
        dispatch(filterDocumentsDataByYears([
          activeDocumentGroupName.toString(),
        ]));
      }
    } else {
      dispatch(selectDocumentsData());
    }
  }, []);

  return (
    <div
      className={classNames(style.container, 'z-[1000]')} data-test='download-files-modal-container'
    >
      <div className='relative flex size-full items-center justify-center'>
        <div className='main-white-card relative size-full rounded-none dt:size-auto dt:rounded-main'>
          <span
            className='absolute right-1 top-1 z-[1010] size-1.75 cursor-pointer dt:right-[0.375rem] dt:top-[0.375rem] dt:size-1.5'
            data-test='download-files-modal-close-button'
            onClick={handleCloseModal}
          >
            { mobile ? <Svgicon id='menu-close' /> : <Svgicon className='rounded-[50%] bg-main-700 text-white-999' id='close-1f' />}
          </span>
          {organizationsPending ? <div className='flex size-full items-center dt:min-h-[34rem] dt:w-[31rem]'><Spinner /></div> : <DownloadFilesSelectFilters />}
        </div>
      </div>
    </div>
  );
};

export { DownloadFilesModal };
