import LoginImage from '../../assets/images/login_image.png';
import {
  FiltersContext,
  SignUpActivationContext,
  SignUpActivationProvider,
} from '../../contexts';
import style from './Authorization.module.scss';
import { ROUTE_ARRAY } from './routes';
import { organizationsApi } from 'api/organizationsApi';
import userPublicApi from 'api/user-public';
import {
  LoginContactSupport,
  LoginHeader,
  RouteMatcher,
  SkipEnhancedSecurityModal,
} from 'components';
import { Error } from 'pages/Error';
import { getLogoWidth } from 'pages/utils';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { getItemsFromStorage,
  storeItemsInStorage } from 'types';

const AuthorizationWithoutContext = () => {
  const { isSignUp,
    showSecurityModal } = useContext(SignUpActivationContext);

  const { pathname,
    search } = useLocation();
  const queryParameters = new URLSearchParams(search);
  const { dataToken,
    token } = Object.fromEntries(queryParameters.entries());
  const navigate = useNavigate();
  const { setFirmId } = useContext(FiltersContext);

  const [
    orgLogo,
    setOrgLogo,
  ] = useState('');

  const [
    logoSize,
    setLogoSize,
  ] = useState('Medium');

  const isTokenExpired = getItemsFromStorage([
    'isTokenExpired',
  ], sessionStorage) || 'true';

  // should be check === 'true' because the field could be completely absent or could be false, and only if true we should hide the logo
  const tokenExpired = isTokenExpired.isTokenExpired === 'true';

  const getOrgLogo = useCallback(async () => {
    if (dataToken) {
      try {
        const res = await organizationsApi.getOrgData(dataToken);
        const data = res?.data;
        if (data) {
          setOrgLogo(`${data.logo}?t=${Date.now()}`);
          setFirmId(data.firmId);
          setLogoSize(data.logoSize);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching organization data:', error);
      }
    } else {
      setFirmId('');
    }

    if (token && pathname.includes('/signup')) {
      try {
        const { isActive } = await userPublicApi.getUserIsActive(token);
        if (isActive) {
          storeItemsInStorage({ isTokenExpired: 'false' }, sessionStorage);
          navigate({
            pathname: 'login',
            search: createSearchParams({ dataToken }).toString(),
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error checking user activation:', error);
      }
    }
  }, [
    dataToken,
    isTokenExpired,
    navigate,
    pathname,
    setFirmId,
    token,
  ]);

  useEffect(() => {
    getOrgLogo();
  }, []);

  return (
    <>
      <LoginHeader />
      <main className={style['login-content-wrapper']}>
        <div className={style['form-wrapper']}>
          {orgLogo && !tokenExpired && <div className='flex items-center justify-center pt-[1rem] dt:items-end dt:justify-start dt:pb-1'>
            <div
              className='flex h-auto items-center justify-center dt:justify-start' data-test='firm-logo' style={{
                width: getLogoWidth(logoSize) }}
            >
              <img alt='orgLogo' className='w-full' src={orgLogo} />
            </div>
          </div>}
          <div className='flex flex-col gap-1'>
            <RouteMatcher fallbackElement={Error} subRoutes={ROUTE_ARRAY} />
            {!isSignUp && <LoginContactSupport />}
          </div>
        </div>
        <div className={style['picture-wrapper']}>
          <img alt='documents' src={LoginImage} />
        </div>
      </main>
      {showSecurityModal && <SkipEnhancedSecurityModal />}
    </>
  );
};

export const Authorization = () => {
  return (
    <SignUpActivationProvider>
      <AuthorizationWithoutContext />
    </SignUpActivationProvider>
  );
};
