import { type PasswordValidations } from 'components/SettingsModule/contexts/ChangePassword.context';
import { type TreeNode } from 'components/TreeMultiSelector/TreeMultiSelector';
import { type NestedOrganization } from 'types';
// eslint-disable-next-line import/no-extraneous-dependencies
import zxcvbn from 'zxcvbn';

type DebouncedFunction
    <T extends (...args: any[]) => void> = (...args: Parameters<T>) => void;

export const debounce = <T extends (...args: any[]) => void>
(func: T, delay: number): DebouncedFunction<T> => {
  let timeoutId: NodeJS.Timeout | undefined;

  return (...args: Parameters<T>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const downloadLinkCreator = (name: string, blob: Blob | null, url?: string) => {
  const downloadUrl = url || window.URL.createObjectURL(blob as Blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = downloadUrl;
  downloadLink.download = name;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  window.URL.revokeObjectURL(downloadUrl);
};

export const validatePassword = (password: string): PasswordValidations => {
  const analysis = zxcvbn(password);

  return {
    enoughComplex: analysis.score > 2,
    hasLatinLetters: /^[\w!"#$%&'()*+,\-./:;<=>?@[\\\]^`{|}~]+$/u.test(password),
    hasLowercase: /[a-z]/u.test(password),
    hasNumber: /\d/u.test(password),
    hasSymbol: /[!"#$%&()*,.:<>?@^{|}]/u.test(password),
    hasUppercase: /[A-Z]/u.test(password),
    minLength: password.length >= 12,
    strengthScore: analysis.score === 0 ? 1 : analysis.score,
  };
};

export const getPeriodOptions = (organization: NestedOrganization): TreeNode[] => {
  const yearMap = organization?.funds?.reduce((map, fund) => {
    for (const acc of fund.accounts) {
      if (acc.yearsAndQuarters) {
        for (const { quarters,
          year } of acc.yearsAndQuarters) {
          if (!map.has(year)) {
            map.set(year, new Set());
          }

          for (const quarter of quarters) {
            map.get(year)?.add(quarter);
          }
        }
      }
    }

    return map;
  }, new Map<number | string, Set<string>>()) ?? new Map<number | string, Set<string>>();

  return Array.from(yearMap.entries())
    .sort(([
      yearA,
    ], [
      yearB,
    ]) => yearB.toString().localeCompare(yearA.toString()))
    .map(([
      year,
      quarters,
    ]) => {
      const sortedQuarters = Array.from(quarters)
        .sort((a, b) => b?.localeCompare(a));

      return {
        children: sortedQuarters.map((quarter) => ({
          id: `${year}/${quarter}`,
          name: `${quarter} ${year}`,
        })),
        id: `${year}`,
        name: `${year}`,
      };
    });
};

export const arraysEqual = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sorted1 = [
    ...arr1,
  ].sort();
  const sorted2 = [
    ...arr2,
  ].sort();
  return sorted1.every((value, index) => value === sorted2[index]);
};
