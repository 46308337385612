import './ReportsModule.scss';
import { Alert } from '../../api/utils';
import { type DocumentsStoreState } from '../../store/slices';
import { getFMSUrl } from './ReportSelectors';
import { EmptyStateCard } from 'components/EmptyStateBanner/EmptyStateBanner';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { ModalActivationContext } from 'contexts';
import {
  type CSSProperties,
  useContext,
  useEffect,
  useMemo,
  useRef} from 'react';
import { useSelector } from 'react-redux';

export const ReportsModule = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { togglePerformanceReportsModal } = useContext(ModalActivationContext);

  const {
    otherCategories,
    reports,
    reportsError,
    reportsPending,
  } = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents,
  ) as DocumentsStoreState;

  const onClickViewReportsModal = async () => {
    const reportedPeriodsAmount = reports?.length;
    const firstPeriodHasExactlyOneReport = reports && reports[0]?.reports.length === 1;
    if (reportedPeriodsAmount === 1 && firstPeriodHasExactlyOneReport) {
      const newWindow = window.open('', '_blank');
      const url = await getFMSUrl(reports[0].reports[0]._id);
      if (newWindow) {
        newWindow.location.href = url;
      }
    } else {
      togglePerformanceReportsModal(true);
    }
  };

  const containerStyle = useMemo(() => {
    const newContainerStyle: CSSProperties = {};

    if (reportsPending || reports && reports.length === 0) {
      newContainerStyle.display = 'none';
    }

    return newContainerStyle;
  }, [
    reports,
    reportsPending,
  ]);

  useEffect(() => {
    if (reportsError) {
      Alert(reportsError);
    }
  }, [
    reportsError,
  ]);

  if (!otherCategories?.length) {
    return <EmptyStateCard
      containerClass='reports'
      dataTest='performance-reports-modal-open'
      description='Portfolio overview'
      hasItems={Boolean(reports?.length)}
      onViewClick={onClickViewReportsModal}
      title='Performance reports'
    />;
  }

  return (
    <div
      className='reports-module flex w-full max-w-[90rem] justify-between rounded-main p-[0.875rem]'
      ref={containerRef}
      style={containerStyle}
    >
      <div className='flex flex-col justify-between'>
        <h3 className='text-font-1 font-medium'>
          Performance reports
        </h3>
        {
          reports && <Button
            className='!h-8 w-[5.5rem]'
            data-test='performance-reports-modal-open'
            onClick={onClickViewReportsModal}
            size='medium'
            tooltipWrapperClass='!justify-start'
            type='total-black'
          >
            View
          </Button>
        }
      </div>
    </div>
  );
};
