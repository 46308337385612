import { createContext,
  type FC,
  useCallback,
  useMemo,
  useState } from 'react';

type ModalActivationContextType = {
  activeDocumentGroupName: string | null,
  fileViewModal: { fileNameProps:
  {fileName: string, groupName: string, period: number | string,} | null,
  id: string | null, show: boolean,},
  showDownloadsModal: boolean,
  showPerformanceReportsModal: boolean,
  showSettingsModal: boolean,
  showSupportModal: boolean,
  toggleDownloadsModal: (on: boolean, documentGroupName: string | null) => void,
  toggleFileViewModal: (on: boolean, fileData?:
  {
    fileNameProps: {fileName: string, groupName: string, period: number | string,} | null,
    id: string,
  }) => void,
  togglePerformanceReportsModal: (on: boolean) => void,
  toggleSettingsModal: (on: boolean) => void,
  toggleSupportModal: (on: boolean) => void,
};

type ModalActivationProviderProps = {
  children?: React.ReactNode,
};

const MODAL_ACTIVATION_INITIAL_STATE: ModalActivationContextType = {
  activeDocumentGroupName: null,
  fileViewModal: {
    fileNameProps: null,
    id: null,
    show: false,
  },
  showDownloadsModal: false,
  showPerformanceReportsModal: false,
  showSettingsModal: false,
  showSupportModal: false,
  toggleDownloadsModal: () => {},
  toggleFileViewModal: () => {},
  togglePerformanceReportsModal: () => {},
  toggleSettingsModal: () => {},
  toggleSupportModal: () => {},
};

export const ModalActivationContext = createContext<ModalActivationContextType>(
  MODAL_ACTIVATION_INITIAL_STATE,
);

export const ModalActivationProvider: FC<
ModalActivationProviderProps> = ({children}) => {
  const [
    showSupportModal,
    setShowSupportModal,
  ] = useState(false);
  const [
    showDownloadsModal,
    setShowDownloadsModal,
  ] = useState(false);
  const [
    activeDocumentGroupName,
    setActiveDocumentGroupName,
  ] = useState<string | null>(null);
  const [
    showPerformanceReportsModal,
    setShowPerformanceReportsModal,
  ] = useState(false);
  const [
    showSettingsModal,
    setShowSettingsModal,
  ] = useState(false);

  const [
    fileViewModal,
    setFileViewModal,
  ] = useState<{
    fileNameProps: {fileName: string, groupName: string, period: number | string,} | null,
    id: string | null,
    show: boolean,
  }>({
    fileNameProps: null,
    id: null,
    show: false,
  });

  const toggleFileViewModal = useCallback(
    (
      on: boolean,
      fileData?: {
        fileNameProps?: {
          fileName: string,
          groupName: string,
          period: number | string,
        } | null,
        id: string,
      },
    ) => {
      setFileViewModal({
        fileNameProps: fileData?.fileNameProps || null,
        id: on && fileData ? fileData.id : null,
        show: on,
      });
    },
    [
      setFileViewModal,
    ],
  );

  const toggleDownloadsModal = useCallback(
    (on: boolean, group: string | null) => {
      setShowDownloadsModal(on);
      setActiveDocumentGroupName(group);
    }, [
      setActiveDocumentGroupName,
      setShowDownloadsModal,
    ],
  );

  const toggleSupportModal = useCallback(
    (on: boolean) => setShowSupportModal(on), [
      setShowSupportModal,
    ],
  );

  const togglePerformanceReportsModal = useCallback(
    (on: boolean) => setShowPerformanceReportsModal(on), [
      setShowPerformanceReportsModal,
    ],
  );

  const toggleSettingsModal = useCallback(
    (on: boolean) => setShowSettingsModal(on), [
      setShowSettingsModal,
    ],
  );

  const values = useMemo(() => ({
    activeDocumentGroupName,
    fileViewModal,
    showDownloadsModal,
    showPerformanceReportsModal,
    showSettingsModal,
    showSupportModal,
    toggleDownloadsModal,
    toggleFileViewModal,
    togglePerformanceReportsModal,
    toggleSettingsModal,
    toggleSupportModal,
  }), [
    activeDocumentGroupName,
    fileViewModal,
    showDownloadsModal,
    showPerformanceReportsModal,
    showSettingsModal,
    showSupportModal,
    toggleDownloadsModal,
    toggleFileViewModal,
    togglePerformanceReportsModal,
    toggleSettingsModal,
    toggleSupportModal,
  ]);

  return <ModalActivationContext.Provider value={values}>
    { children }
  </ModalActivationContext.Provider>;
};

