import style from './ResetPasswordConfirm.module.scss';
import authApi from 'api/auth';
import classNames from 'classnames';
import {
  LoginButton,
  LoginHeading,
  LoginPasswordInput,
  Spinner} from 'components';
import {
  isPasswordValid,
  PasswordRequirements } from 'components/PasswordRequirements/PasswordRequirements';
import { PasswordStrengthColorBar } from 'components/PasswordStrengthColorBar/PasswordStrengthColorBar';
import { ChangePasswordContext } from 'components/SettingsModule/contexts/ChangePassword.context';
import {
  validatePassword } from 'helpers/helpers';
import { useAuthCheck,
  useToken } from 'hooks';
import { ExpiredLink } from 'pages/ExpiredLink/ExpiredLink';
import React, {
  useContext,
  useEffect,
  useState } from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { passwordRequirements,
  strongPasswordMessage } from 'resources/constants';
import { notificationsService } from 'services';
import { type AppDispatch } from 'store';
import isStrongPassword from 'validator/es/lib/isStrongPassword';

export const ResetPasswordConfirm = () => {
  const {
    passwordValidations,
    setPasswordValidations,
  } = useContext(ChangePasswordContext);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const token = useToken();
  if (token) {
    sessionStorage.setItem('canResetPassword', 'true');
  }

  useAuthCheck();

  // @ts-expect-error state not typed :(
  const isLoading = useSelector((state) => state.global.loading) as boolean;

  const [
    password,
    setPassword,
  ] = useState('');
  const [
    checkTokenLoading,
    setCheckTokenLoading,
  ] = useState(true);
  const [
    isTokenExpired,
    setIsTokenExpired,
  ] = useState(false);
  const [
    confirmPassword,
    setConfirmPassword,
  ] = useState('');

  useEffect(() => {
    document.title = 'Reset password confirm';
    const verifyTokenAsync = async () => {
      setCheckTokenLoading(true);
      try {
        await authApi.verifyToken(token, setIsTokenExpired);
      } finally {
        setCheckTokenLoading(false);
      }
    };

    verifyTokenAsync();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
  ]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      notificationsService.error('Password and confirm password does not match');
      return;
    }

    if (!isStrongPassword(password, passwordRequirements)) {
      notificationsService.error(strongPasswordMessage);
      return;
    }

    const payload = { password,
      token };
    dispatch(authApi.confirmForgotPassword(payload, navigate));
  };

  const handlePasswordChange = (newPassword: string) => {
    setPassword(newPassword);
    setPasswordValidations(validatePassword(newPassword));
  };

  const updatePasswordComponent = () => <div className='flex flex-col gap-6'><LoginHeading>Update password</LoginHeading>
    <form onSubmit={onSubmit}>
      <div className={classNames(style.inputsWrapper, 'flex flex-col gap-[1rem]')}>
        <div className='flex flex-col gap-[0.25rem]'>
          <LoginPasswordInput
            name='reset-password-confirm-new-password-input'
            onChange={handlePasswordChange}
            placeholder='New Password'
            styles='!mb-0'
            value={password}
          />
          <PasswordStrengthColorBar
            displayPassword={password}
            passwordValidations={passwordValidations}
          />
        </div>
        <div className={style.inputsWrapper}>
          <LoginPasswordInput
            name='reset-password-confirm-confirm-password-input'
            onChange={setConfirmPassword}
            placeholder='Confirm Password'
            styles='!mb-0'
            value={confirmPassword}
          />
        </div>
        <PasswordRequirements
          confirmDisplayPassword={confirmPassword}
          displayPassword={password}
          isFromSettings={false}
        />
        <LoginButton
          disabled={!isPasswordValid(passwordValidations, password, confirmPassword)}
          isLoading={isLoading}
          name='reset-password-confirm-button'
          text='Update password'
          type='submit'
        />
      </div>
    </form></div>;

  const renderComponent = () => {
    if (checkTokenLoading) {
      return <Spinner />;
    } else if (isTokenExpired) {
      return <ExpiredLink />;
    }

    return updatePasswordComponent();
  };

  return (
    <div className={style.loginWrapper}>
      {renderComponent()}
    </div>
  );
};
