import { ButtonArea } from 'components/SettingsModule/ButtonArea/ButtonArea';
import { VerificationNumberInput } from 'components/SettingsModule/ChangePhoneNumberBlock/VerificationNumberInput/VerificationNumberInput';
import { useBlockDisplay } from 'components/SettingsModule/contexts/BlockDisplayContext.context';
import { ChangePhoneNumberContext } from 'components/SettingsModule/contexts/ChangePhoneNumber.context';
import { type FC,
  useContext } from 'react';
import { notificationsService } from 'services';

type MethodProps = {
  isChangingVerification?: boolean,
  selectedMethod: string,
  setActiveMethod: (method: string) => void,
};

export const ConfirmBlock: FC<MethodProps> = ({ isChangingVerification,
  selectedMethod,
  setActiveMethod }) => {
  const {
    addVerificationNumber,
    canSendVerificationCode,
    setSubmitVerificationErrors,
    submitVerificationErrors,
    submitVerificationLoading,
    verificationNumber,
    verifyVerificationNumber,
  } = useContext(ChangePhoneNumberContext);

  const { setActiveBlock } = useBlockDisplay();

  const handleSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const result = await verifyVerificationNumber();
    if (result.success) {
      setActiveBlock('edit-fields');
      notificationsService.success('Verification method was changed successfully');
    }
  };

  return <div className='flex h-full flex-col justify-between dt:h-auto dt:justify-normal'>
    <VerificationNumberInput
      errorMessages={submitVerificationErrors}
      isChangingVerification={isChangingVerification}
      name={'Verification Code'}
      onChange={addVerificationNumber}
      selectedMethod={selectedMethod}
      setSubmitVerificationErrors={setSubmitVerificationErrors}
      value={verificationNumber}
    />
    <ButtonArea
      buttonText='Save'
      disabled={!canSendVerificationCode}
      isLoading={submitVerificationLoading}
      onCancel={() => setActiveMethod('change-verification')}
      onSave={(event) => handleSubmit(event as React.MouseEvent<HTMLElement>)}
    />
  </div>;
};
