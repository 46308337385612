import authApi from 'api/auth';
import { type FC,
  useEffect,
  useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import VerificationInput from 'react-verification-input';
import { resendVerificationTimeoutInS } from 'resources/config';
import { type VerificationChannel } from 'types';

const MAX_LENGTH = 6;
const PLACEHOLDER = '•';

type VerificationNumberInputProps = {
  errorMessages: string[],
  isChangingVerification?: boolean,
  name: string,
  onChange: (input: string) => void,
  selectedMethod?: string,
  setSubmitVerificationErrors?: React.Dispatch<React.SetStateAction<string[]>>,
  value: string,
};

export const VerificationNumberInput: FC<VerificationNumberInputProps> = ({
  errorMessages,
  isChangingVerification,
  name,
  onChange,
  selectedMethod,
  setSubmitVerificationErrors,
  value,
}) => {
  const [
    countdown,
    setCountdown,
  ] = useState(resendVerificationTimeoutInS);
  const refreshToken = sessionStorage.getItem('refresh_token');

  const resendClick = () => {
    if (setSubmitVerificationErrors) {
      setSubmitVerificationErrors([]);
    }

    authApi.changeVerificationMethodFromSettings({
      refreshToken,
      verificationChannel: selectedMethod as VerificationChannel,
    });
    setCountdown(30);
  };

  const getCountdownText = (count: number) => <div className='flex gap-[0.25rem]'>
    <span className=' text-font-2 font-normal text-black-700'>
      Resend code
    </span>
    {Boolean(count) && <p className='m-0 text-font-2 font-normal text-black-700'> in <span className='text-font-2 font-bold text-black-700'>{countdown} sec</span></p>}
  </div>;

  const linkToMethodUpdate = () =>
    <span className='cursor-pointer text-font-2 font-normal text-main-700' data-test='resend-verification-code-link' onClick={resendClick}>
      Resend code
    </span>;

  const timerOrLink = countdown === 0 ? linkToMethodUpdate() :
    getCountdownText(countdown);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      }
    }, 1_000);
    return () => clearInterval(countdownInterval);
  }, [
    countdown,
  ]);

  return <div className='flex w-full flex-col'>
    <span className='font-inter text-[0.75rem] font-normal text-black-999'>{name}</span>
    <div className='relative mb-[0.5rem] flex h-[2.125rem] items-center justify-between rounded border border-[#CCC] py-[0.25rem] pl-[0.5rem] pr-[0.25rem]'>
      <VerificationInput
        autoFocus
        classNames={{
          character: 'font-inter text-[0.875rem] border-none leading-[1.25rem]',
          characterInactive: 'color-transparent bg-transparent',
          container: 'h-[1.25rem] w-[10rem]',
        }}
        inputProps={{ autoComplete: 'one-time-code' }}
        length={MAX_LENGTH}
        onChange={onChange}
        placeholder={PLACEHOLDER}
        validChars='0-9'
        value={value}
      />
    </div>
    {
      errorMessages.length > 0 &&
      <ul className='font-inter list-inside list-disc text-[0.625rem] text-label-r-300'>
        {errorMessages.map((error) =>
          <li key={error}>{error}</li>,
        )}
      </ul>
    }
    {isChangingVerification && timerOrLink}
  </div>;
};
