import { type ChangePasswordProperties,
  validatePasswords } from './utils';
import authApi from 'api/auth';
import { createContext,
  type FC,
  useCallback,
  useMemo,
  useState } from 'react';

export type PasswordValidations = {
  enoughComplex: boolean,
  hasLatinLetters: boolean,
  hasLowercase: boolean,
  hasNumber: boolean,
  hasSymbol: boolean,
  hasUppercase: boolean,
  minLength: boolean,
  strengthScore: number,
};

type ChangePasswordContextType = {
  addCurrentPassword: (currentPasswordInput: string) => void,
  addNewPassword: (newPasswordInput: string) => void,
  addRetypeNewPassword: (retypeNewPasswordInput: string) => void,
  changePasswordErrors: Record<ChangePasswordProperties, string[]>,
  changePasswordLoading: boolean,
  changePasswordSuccess: boolean,
  currentPassword: string,
  newPassword: string,
  passwordValidations: PasswordValidations,
  retypeNewPassword: string,
  setPasswordValidations: React.Dispatch<React.SetStateAction<PasswordValidations>>,
  submitPasswordBody: () => Promise<void>,
};

type ChangePasswordContextProviderProps = {
  children?: React.ReactNode,
};

const INITIAL_STATE: ChangePasswordContextType = {
  addCurrentPassword: () => {},
  addNewPassword: () => {},
  addRetypeNewPassword: () => {},
  changePasswordErrors: {
    currentPassword: [],
    newPassword: [],
    retypeNewPassword: [],
  } as Record<ChangePasswordProperties, string[]>,
  changePasswordLoading: false,
  changePasswordSuccess: false,
  currentPassword: '',
  newPassword: '',
  passwordValidations: {
    enoughComplex: false,
    hasLatinLetters: false,
    hasLowercase: false,
    hasNumber: false,
    hasSymbol: false,
    hasUppercase: false,
    minLength: false,
    strengthScore: 0,
  },
  retypeNewPassword: '',
  setPasswordValidations: () => {},
  submitPasswordBody: () => Promise.resolve(),
};

export const ChangePasswordContext = createContext<ChangePasswordContextType>(
  INITIAL_STATE,
);

export const ChangePasswordContextProvider: FC<
ChangePasswordContextProviderProps> = ({ children }) => {
  const [
    passwordValidations,
    setPasswordValidations,
  ] = useState<PasswordValidations>({
    enoughComplex: false,
    hasLatinLetters: false,
    hasLowercase: false,
    hasNumber: false,
    hasSymbol: false,
    hasUppercase: false,
    minLength: false,
    strengthScore: 0,
  });
  const [
    currentPassword,
    setCurrentPassword,
  ] = useState('');
  const [
    newPassword,
    setNewPassword,
  ] = useState('');
  const [
    retypeNewPassword,
    setRetypeNewPassword,
  ] = useState('');
  const [
    changePasswordErrors,
    setChangePasswordErrors,
  ] = useState<Record<ChangePasswordProperties, string[]>>({
    currentPassword: [],
    newPassword: [],
    retypeNewPassword: [],
  });
  const [
    changePasswordLoading,
    setChangePasswordLoading,
  ] = useState(false);
  const [
    changePasswordSuccess,
    setChangePasswordSuccess,
  ] = useState(false);

  const addCurrentPassword = useCallback(
    (currentPasswordInput: string) => setCurrentPassword(currentPasswordInput), [
      setCurrentPassword,
    ]);

  const addNewPassword = useCallback(
    (newPasswordInput: string) => setNewPassword(newPasswordInput), [
      setNewPassword,
    ]);

  const addRetypeNewPassword = useCallback(
    (retypeNewPasswordInput: string) => setRetypeNewPassword(retypeNewPasswordInput), [
      setRetypeNewPassword,
    ]);

  const submitPasswordBody = useCallback(async () => {
    const {
      canSubmit,
      errors: validationErrors,
    } = validatePasswords({
      currentPassword,
      newPassword,
      retypeNewPassword,
    });

    if (!canSubmit) {
      setChangePasswordSuccess(false);
      setChangePasswordErrors(validationErrors);
      return;
    }

    setChangePasswordLoading(true);
    const {
      changePasswordSuccess: submissionSuccess,
      submissionErrors,
    } = await authApi.changePassword({
      confirmPassword: retypeNewPassword,
      newPassword,
      password: currentPassword,
    });
    setChangePasswordLoading(false);
    setChangePasswordErrors(submissionErrors);
    setChangePasswordSuccess(submissionSuccess);
  }, [
    currentPassword,
    newPassword,
    retypeNewPassword,
  ]);

  const values = useMemo(() => ({
    addCurrentPassword,
    addNewPassword,
    addRetypeNewPassword,
    changePasswordErrors,
    changePasswordLoading,
    changePasswordSuccess,
    currentPassword,
    newPassword,
    passwordValidations,
    retypeNewPassword,
    setPasswordValidations,
    submitPasswordBody,
  }), [
    addCurrentPassword,
    addNewPassword,
    addRetypeNewPassword,
    changePasswordErrors,
    changePasswordLoading,
    changePasswordSuccess,
    currentPassword,
    newPassword,
    passwordValidations,
    retypeNewPassword,
    setPasswordValidations,
    submitPasswordBody,
  ]);

  return <ChangePasswordContext.Provider value={values}>
    {children}
  </ChangePasswordContext.Provider>;
};
