import { useAuthCheck,
  useDocumentTitle } from '../../../hooks';
import {
  getItemsFromStorage,
  type NextPageAfterVerificationFromStorage,
  removeItemsFromStorage } from '../../../types';
import {
  getSendToParagraph,
  useVerificationData } from '../utils';
import authApi from 'api/auth';
import { LoginBackLink,
  LoginButton,
  LoginInput,
  LoginLink } from 'components';
import { useEffect,
  useState } from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resendVerificationTimeoutInS } from 'resources/config';
import { type AppDispatch } from 'store';

const INTERVAL_TO_CHECK_EXPIRY = 60_000;

const linkToMethodUpdate = (to = '/auth/verification/method') =>
  <LoginLink to={to}>
    Resend code or try another way
  </LoginLink>;

const getCountdownText = (countdown: number) => <div className='flex gap-[0.25rem] self-center dt:self-start'>
  <span className=' text-font-2 font-normal text-black-700'>
    Resend code or try another way
  </span>
  {Boolean(countdown) && <p className='m-0 text-font-2 font-normal text-black-700'> in <span className='text-font-2 font-bold text-black-700'>{countdown} sec</span></p>}
</div>;

export const Verification = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [
    code,
    setCode,
  ] = useState('');
  const [
    countdown,
    setCountdown,
  ] = useState(resendVerificationTimeoutInS);
  const [
    { channel,
      to },
  ] = useVerificationData();

  useAuthCheck();
  useDocumentTitle('Verification');
  const previousPage = sessionStorage.getItem('prevPage') || undefined;

  const renderResendMessage = sessionStorage.getItem('withoutResend') !== 'true';

  // @ts-expect-error state not typed :(
  const isLoading = useSelector((state) => state.global.loading) as boolean;

  const {
    nextPageAfterVerification,
  } = getItemsFromStorage<NextPageAfterVerificationFromStorage>([
    'nextPageAfterVerification',
  ], sessionStorage);

  const afterVerificationCallback = () => {
    removeItemsFromStorage<
    NextPageAfterVerificationFromStorage>([
      'nextPageAfterVerification',
    ], sessionStorage);
    // change later to syntax above
    sessionStorage.removeItem('prevPage');
    sessionStorage.removeItem('withoutResend');
  };

  const targetNextPage = nextPageAfterVerification || '/firms';

  const checkExpiration = () => {
    const expiresAtString = sessionStorage.getItem('expiresAt');
    if (expiresAtString) {
      const expiresAt = new Date(expiresAtString);
      const currentDate = new Date();

      // Convert both dates to UTC time
      const expiresAtUTC = Date.UTC(
        expiresAt.getFullYear(),
        expiresAt.getMonth(),
        expiresAt.getDate(),
        expiresAt.getHours(),
        expiresAt.getMinutes(),
        expiresAt.getSeconds());
      const currentDateUTC = Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        currentDate.getHours(),
        currentDate.getMinutes(),
        currentDate.getSeconds(),
      );

      if (currentDateUTC > expiresAtUTC) {
        // Navigate back to the login page if expired
        sessionStorage.clear();
        navigate('/auth/verification/method', { replace: true });
      }
    }
  };

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      }
    }, 1_000);
    return () => clearInterval(countdownInterval);
  }, [
    countdown,
  ]);

  useEffect(() => {
    checkExpiration();

    // Set up an interval to check expiration periodically (e.g., every minute)
    const intervalId = setInterval(checkExpiration, INTERVAL_TO_CHECK_EXPIRY);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  // Only set interval once when component is rendered
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payload = {
      code,
    };
    dispatch(
      authApi.verification(
        payload,
        navigate,
        targetNextPage,
        afterVerificationCallback,
      ),
    );
  };

  const setCodeAndAutoSubmit = (newCode: string) => {
    setCode(newCode);
    if (newCode.length === 6) {
      const payload = { code: newCode };
      dispatch(
        authApi.verification(
          payload,
          navigate,
          targetNextPage,
          afterVerificationCallback,
        ),
      );
    }
  };

  const timerOrLink = countdown === 0 ?
    linkToMethodUpdate(renderResendMessage ? '/auth/verification/method' : previousPage) : getCountdownText(countdown);

  const getDisclaimer = () => <span className='text-center text-font-2 leading-[130%] text-black-600 dt:text-start'>
    To help keep you account safe,
    Orca wants to make sure it’s really you trying to sign in
  </span>;

  return <div className='flex flex-col px-1 dt:max-w-[18.75rem] dt:px-0'>
    <div className='flex flex-col gap-0.5'>
      <LoginBackLink
        to={previousPage}
      />
      {getSendToParagraph(channel, to)}
      {getDisclaimer()}
    </div>
    <div className='h-2' />
    <form onSubmit={onSubmit}>
      <div className='flex flex-col gap-1'>
        <div className='flex flex-col gap-0.25'>
          <LoginInput className='!mb-0' name='verification-code-input' onChange={setCodeAndAutoSubmit} placeholder='Enter Code' shouldFocus type='number' value={code} />
          {timerOrLink}
        </div>
        <LoginButton disabled={!code} isLoading={isLoading} name='verification-button' text='Verify' type='submit' />
      </div>
    </form>
  </div>;
};
