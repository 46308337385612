import { calcSummaryRow,
  getInitialTableColumnDisplayValues,
  setSummaryStatistics } from '../../../../store/slices';
import {
  type ColumnTitle,
  type ComputedCapitalAccountFund,
  type SelectedFundAccountPeriodMap} from '../../../../types';
import { formatNumber } from '../../../Pipes/numberPipes';
import { Table } from 'antd';
import { type FC,
  useEffect,
  useState } from 'react';
import { useDispatch } from 'react-redux';

export type CapitalAccountsSummaryTableRowProps = {
  data: ComputedCapitalAccountFund[],
  filteredSecondTableColumns: Array<[string, ColumnTitle]>,
  selectedFundAccountPeriodMap: SelectedFundAccountPeriodMap,
};

export const CapitalAccountsSummaryTableRow:
FC<CapitalAccountsSummaryTableRowProps> = ({
  data,
  filteredSecondTableColumns,
  selectedFundAccountPeriodMap,
}) => {
  const dispatch = useDispatch();
  const [
    columnValues,
    setColumnValues,
  ] = useState(getInitialTableColumnDisplayValues('Total'));

  const hasColumnWithTitle = (title: string) =>
    filteredSecondTableColumns.some((col) => col[1].title === title);

  useEffect(() => {
    const newTotalColumnValues = calcSummaryRow(data, selectedFundAccountPeriodMap);
    dispatch(setSummaryStatistics({total: newTotalColumnValues}));
    setColumnValues(newTotalColumnValues);
  }, [
    data,
    dispatch,
    selectedFundAccountPeriodMap,
  ]);
  return <Table.Summary.Row className='bg-black-200'>
    <Table.Summary.Cell className='!border-none !bg-black-200' index={0} />
    <Table.Summary.Cell className='min-w-[21.25rem] !border-none !bg-black-200 font-medium' index={1}>{columnValues.legalName}</Table.Summary.Cell>
    <Table.Summary.Cell className='!border-none !bg-black-200' index={2} />
    <Table.Summary.Cell className='!border-none !bg-black-200  !px-0.25 font-medium' index={3}>{formatNumber(columnValues.commitment, 0, true)}</Table.Summary.Cell>
    <Table.Summary.Cell className='!border-b-none border-custom-gray border-r bg-white-900' index={4} />
    <Table.Summary.Cell className='!border-none !bg-black-200  !px-0.25 font-medium' index={5}>{formatNumber(columnValues.contribution, 0, true)}</Table.Summary.Cell>
    {hasColumnWithTitle('(Due)/Advance') && <Table.Summary.Cell className='!border-none !bg-black-200  !px-0.25 font-medium' index={6}>{formatNumber(columnValues.contributionDue, 0, true)}</Table.Summary.Cell>}
    {hasColumnWithTitle('Distribution') && <Table.Summary.Cell className='!border-none !bg-black-200' index={7}>{formatNumber(columnValues.distribution, 0, true)}</Table.Summary.Cell>}
    {hasColumnWithTitle('Withholding Tax') && <Table.Summary.Cell className='!border-none !bg-black-200' index={8}>{formatNumber(columnValues.distributionWth, 0, true)}</Table.Summary.Cell>}
    <Table.Summary.Cell className='!border-none !bg-black-200  !px-0.25 font-medium' index={9}>{formatNumber(columnValues.unrealizedGainLoss, 0, true)}</Table.Summary.Cell>
    {hasColumnWithTitle('Real. portf.') && <Table.Summary.Cell className='!border-none !bg-black-200' index={10}>{formatNumber(columnValues.realizedGainLoss, 0, true)}</Table.Summary.Cell>}
    <Table.Summary.Cell className='!border-none !bg-black-200  !px-0.25 font-medium' index={11}>{formatNumber(columnValues.other, 0, true)}</Table.Summary.Cell>
    <Table.Summary.Cell className='!border-b-none border-custom-gray border-r bg-white-900' index={12} />
    <Table.Summary.Cell className='!border-none !bg-black-200  !px-0.25 font-medium' index={13}>{formatNumber(columnValues.nav, 0, true)}</Table.Summary.Cell>
  </Table.Summary.Row>;
};
